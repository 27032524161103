import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  cardIcon: {
    right: "20px",
    position: "absolute"
  },
  cvvInput: {
    position: "relative"
  },
  paper: {
    padding: "0px",
    paddingTop: theme.spacing(2)
  },
  gutter: {
    padding: `0 ${theme.spacing(1)}px`,
    paddingBottom: theme.spacing(2)
  }
}));

export default withCustomStyles;
