import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ShoppingIcon from "@material-ui/icons/ShoppingCartOutlined";
import withCustomStyles from "./HeaderPart.style";
import MenuIcon from "@material-ui/icons/Menu";
import SearchPart from "./SearchPart";
import DrawerPart from "./DrawerPart";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import TypographyPart from "./TypographyPart";
import AddressesPart from "./AddressesPart";
import classnames from "classnames";
import gtmService from "../services/GTMService";
import { strLimit } from "../utils/HelperUtils";
import { getLargeLogo } from "../utils/UrlUtils";
import CardMedia from "@material-ui/core/CardMedia";
import ExpandIcon from "@material-ui/icons/ExpandLess";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AppBarSecondRowButtonPart from "./AppBarSecondRowButtonPart";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import Popper from "@material-ui/core/Popper";
import RootRef from "@material-ui/core/RootRef";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import { isEasyPaisaMiniApp } from "../utils/AppUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withSnackbar } from "notistack";
import LoadMorePart from "./LoadMorePart";

@inject(["orderStore"])
@inject(["customerStore"])
@inject(["uiStore"])
@inject(["locationStore"])
@inject(["categoriesStore"])
@inject(["allBrandsStore"])
@inject(["searchProductsStore"])
@inject(["globalSettingStore"])
@observer
class HeaderPart extends Component {
  locationRef = React.createRef();

  state = {
    anchorEl: <></>,
    locError: false,
    locDisabled: "",
    expanded: false,
    drawerOpen: false,
    selectManually: false,
    selectedLocation: null,
    locationAccess: false,
    isLoading: false,
    isOverlayClicked: false
  };

  componentDidMount() {
    const {
      props: {
        location: { pathname },
        locationStore,
        customerStore
      }
    } = this;
    this.setState({ anchorEl: this.locationRef.current });
    setTimeout(() => {
      if (customerStore.isLoggedIn) {
        locationStore.getAddresses();
      }
    }, 200);
    if (pathname === "/unsubscribe") {
      this.handleUpdateLocation({
        lat: "31.5204",
        lng: "74.3587"
      });
    }
  }

  fetchCategoriesAndBrands = () => {
    const { categoriesStore, allBrandsStore } = this.props;

    categoriesStore.fetch();
    allBrandsStore.fetchLimited(50);
  };

  handleDrawerOpen = () => {
    gtmService.pageView("/side-menu");
    this.setState({
      ...this.state,
      expanded: false,
      drawerOpen: true
    });
  };

  handleDrawerClose = () => {
    this.setState({
      ...this.state,
      drawerOpen: false
    });
  };

  selectLocationEvent = eventName => {
    gtmService.event("User", eventName, window.location.pathname);
  };

  handleLocation = () => {
    this.selectLocationEvent("Set_Auto_Location_Selected");
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          if (coords && coords.latitude && coords.longitude) {
            this.setState({
              locationAccess: true
            });
            this.handleUpdateLocation({
              lat: coords.latitude,
              lng: coords.longitude
            });
          }
        },
        () =>
          this.setState({
            locDisabled: "Please allow location from your browser settings"
          })
      );
    }
  };

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleUpdateLocation = coords => {
    const {
      props: {
        uiStore,
        customerStore,
        globalSettingStore,
        location: { pathname },
        history
      },
      state: { locError },
      fetchCategoriesAndBrands
    } = this;
    this.setState({ isLoading: true });
    if (pathname === "/checkout") {
      uiStore.setDefaultDeliveryLocation({
        lat: coords.lat,
        lng: coords.lng,
        location: coords.location
      });
      customerStore.fetchVendor(
        undefined,
        () => {
          fetchCategoriesAndBrands();
          this.setState({ isLoading: false });
          history.push("/cart");
        },
        () => {
          this.setState({ isLoading: false });
        }
      );
    }
    if (pathname !== "/checkout") {
      uiStore.setDefaultDeliveryLocation({
        lat: coords.lat,
        lng: coords.lng
      });
      this.setState({
        selectedLocation: coords
      });
      customerStore.fetchVendor(
        undefined,
        data => {
          if (coords.location) {
            this.selectLocationEvent("Set_Manual_Location_Selected");
          }
          if (locError) this.setState({ locError: false });
          uiStore.setDefaultDeliveryLocation({
            lat: coords.lat,
            lng: coords.lng,
            location: data.name
          });
          fetchCategoriesAndBrands();
          this.setState({ isLoading: false });
          globalSettingStore.registerDevice();
        },
        () => {
          this.setState({ locError: true });
          this.setState({ isLoading: false });
        }
      );
    }
  };

  handleSelectManually = value => {
    this.setState({ selectManually: value });
  };

  handleChangeLocation = () => {
    const {
      props: {
        history,
        enqueueSnackbar,
        customerStore,
        locationStore,
        location: { pathname, search }
      }
    } = this;
    if (
      customerStore.isLoggedIn &&
      locationStore.all.length < 1 &&
      locationStore.getAddressesState === "done" &&
      pathname !== "/add-address"
    ) {
      gtmService.event("User", "Location_tapped_from_Home");
      history.push("/add-address?redirect=/");
    }
    if (!customerStore.isLoggedIn && pathname !== "/change-location") {
      history.push(`/change-location?redirect=${pathname}${search}`);
    } else if (locationStore.all.length > 0) {
      gtmService.event(
        "User",
        "Location_bottom_sheet_opened",
        "From_Home_Page"
      );
      this.toggleExpanded();
    }
    if (
      customerStore.isLoggedIn &&
      locationStore.getAddressesState !== "done" &&
      locationStore.all.length === 0
    ) {
      enqueueSnackbar("Something went wrong. Please try again", {
        variant: "error"
      });
    }
  };

  overlayClicked = () => {
    const {
      state: { isOverlayClicked }
    } = this;
    if (!isOverlayClicked) {
      gtmService.event("User", "Location overlay clicked");
      this.setState({
        isOverlayClicked: true
      });
      setTimeout(() => {
        this.setState({
          isOverlayClicked: false
        });
      }, 300);
    }
  };

  isDefaultLocationAllowedPage = pathname => {
    // untill we are deliverying in single city, allow the browsing with default city selected by default
    return pathname.search("/") === -1;
  };

  render() {
    const {
      props: {
        classes,
        orderStore,
        customerStore: { customer, isLoggedIn, fetchVendorState, vendor },
        uiStore,
        locationStore,
        location: { pathname }
      },
      state: {
        drawerOpen,
        anchorEl,
        expanded,
        locError,
        locDisabled,
        selectManually,
        selectedLocation,
        locationAccess,
        isLoading,
        isOverlayClicked
      },
      handleLocation,
      toggleExpanded,
      // handleSelectManually,
      handleChangeLocation,
      overlayClicked,
      isDefaultLocationAllowedPage
    } = this;

    const location =
      uiStore &&
      uiStore.defaultDeliveryLocation &&
      uiStore.defaultDeliveryLocation.location;

    const isDefaultLocation =
      uiStore &&
      uiStore.defaultDeliveryLocation &&
      uiStore.defaultDeliveryLocation.default;

    const googleAddress =
      isLoggedIn &&
      locationStore.currentAddress &&
      locationStore.currentAddress.single_line_address
        ? locationStore.currentAddress.single_line_address
        : isLoggedIn && customer.address !== "N/A"
        ? customer.address
        : (location === "N/A" && vendor.name) || location || "";

    let addressCharLimit = 15;
    if (uiStore.screenWidth >= 375 && uiStore.screenWidth < 800) {
      addressCharLimit = 20;
    } else if (uiStore.screenWidth >= 800) {
      addressCharLimit = 50;
    }

    return (
      <AppBar position="sticky" className={classes.appBar}>
        <Grid
          direction="column"
          justify="flex-start"
          alignItems="stretch"
          container
        >
          <Grid
            item
            className={classnames(classes.zIndexBar, {
              [classes.backgroundWhite]: expanded
            })}
          >
            <Grid
              container
              wrap="nowrap"
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <RootRef rootRef={this.locationRef}>
                <Grid item>
                  <Grid
                    container
                    wrap="nowrap"
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <IconButton
                        disabled={drawerOpen}
                        className={classes.colorBlack}
                        onClick={this.handleDrawerOpen}
                        aria-label="Menu"
                      >
                        <MenuIcon />
                      </IconButton>
                    </Grid>
                    <Grid item className={classes.noDecoration}>
                      <Grid
                        container
                        wrap="nowrap"
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid
                          item
                          component={Link}
                          to="/"
                          onClick={() => this.setState({ expanded: false })}
                          className={classes.marginRight1}
                        >
                          <CardMedia
                            component="img"
                            alt="logo"
                            className={classes.logo}
                            src={getLargeLogo()}
                          />
                        </Grid>
                        <Grid
                          className={classes.addressBox}
                          onClick={handleChangeLocation}
                          item
                        >
                          <Typography
                            variant={"caption"}
                            className={classes.colorBlack}
                          >
                            Delivering to
                          </Typography>
                          <Typography
                            variant="body2"
                            className={classes.deliveryAddress}
                          >
                            {isDefaultLocation
                              ? location
                              : strLimit(googleAddress, addressCharLimit)}
                            <ExpandIcon
                              fontSize={"small"}
                              className={{
                                [classes.rotate]: expanded,
                                [classes.rotateBack]: !expanded
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </RootRef>
              {uiStore.dataLoaded &&
                !isLoggedIn &&
                (!location || isDefaultLocation) &&
                pathname !== "/change-location" &&
                (isDefaultLocationAllowedPage(pathname) ||
                  uiStore.showLocPopup) &&
                pathname !== "/unsubscribe" && (
                  <Popper
                    open
                    placement="bottom-start"
                    disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: true
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent"
                      },
                      arrow: {
                        enabled: true,
                        element: anchorEl
                      }
                    }}
                    anchorEl={anchorEl}
                    className={classes.popperUi}
                    transition
                  >
                    {({ TransitionProps }) => (
                      <Fade
                        className={`${classes.popperUi} ${
                          classes.locationModalWrap
                        } ${isOverlayClicked ? "shake-animation" : ""}`}
                        {...TransitionProps}
                      >
                        <Paper elevation={3}>
                          {!isEasyPaisaMiniApp() &&
                            !selectManually &&
                            !locDisabled &&
                            !locError && (
                              <>
                                <Typography variant="subtitle1" gutterBottom>
                                  Welcome to GrocerApp.
                                </Typography>
                                <Grid container wrap="nowrap">
                                  <Grid item className={classes.marginRight1}>
                                    <LocationOnIcon
                                      color="primary"
                                      fontSize="large"
                                    />
                                  </Grid>
                                  <Grid item>
                                    <Typography gutterBottom>
                                      Please provide your delivery location to
                                      see products at nearby store.
                                    </Typography>
                                    <Grid container>
                                      <Grid
                                        className={classnames(
                                          classes.marginTop1,
                                          classes.paddingRight1
                                        )}
                                        xs={12}
                                        sm={6}
                                        item
                                      >
                                        <Button
                                          fullWidth
                                          color="primary"
                                          variant="outlined"
                                          // onClick={handleSelectManually}
                                          onClick={() =>
                                            this.handleUpdateLocation(
                                              uiStore.availableLocations[0]
                                            )
                                          }
                                          // isabled={locationAccess}
                                          disabled={isLoading}
                                        >
                                          Set Lahore
                                          {isLoading && (
                                            <CircularProgress
                                              className={classes.btnLoading}
                                            />
                                          )}
                                        </Button>
                                      </Grid>
                                      <Grid
                                        className={classes.marginTop1}
                                        xs={12}
                                        sm={6}
                                        item
                                      >
                                        <Button
                                          fullWidth
                                          color="primary"
                                          variant="contained"
                                          onClick={handleLocation}
                                          className={classes.deliveryBtn}
                                          disabled={locationAccess}
                                        >
                                          Set Delivery Location
                                          {locationAccess && (
                                            <CircularProgress
                                              className={classes.btnLoading}
                                            />
                                          )}
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </>
                            )}
                          {(selectManually ||
                            locDisabled ||
                            locError ||
                            isEasyPaisaMiniApp()) && (
                            <>
                              <Grid
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                              >
                                {!isEasyPaisaMiniApp() && (
                                  <Grid item className={classes.marginBottom1}>
                                    <MyLocationIcon
                                      color="primary"
                                      fontSize="large"
                                    />
                                  </Grid>
                                )}
                                <Grid item>
                                  {!selectManually && !locDisabled && (
                                    <Typography variant="h6" align={"center"}>
                                      {isEasyPaisaMiniApp()
                                        ? "Welcome to GrocerApp"
                                        : "We are not here yet!"}
                                    </Typography>
                                  )}
                                  {locDisabled && (
                                    <Typography variant="h6" align={"center"}>
                                      Location Access Denied!
                                    </Typography>
                                  )}
                                  {selectManually && (
                                    <Typography variant="h6" align={"center"}>
                                      Select Manually
                                    </Typography>
                                  )}
                                  <Typography gutterBottom>
                                    Please select the city you want to place
                                    your order in
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Grid
                                    container
                                    direction="column"
                                    className={classes.marginBottom1}
                                  >
                                    {uiStore.availableLocations.map(cities => (
                                      <Button
                                        className={classnames(
                                          classes.marginRight1,
                                          classes.marginBottom1
                                        )}
                                        onClick={() =>
                                          this.handleUpdateLocation(cities)
                                        }
                                        variant="outlined"
                                        color="primary"
                                        key={cities.lat}
                                        disabled={
                                          fetchVendorState === "fetching"
                                        }
                                      >
                                        {cities.location}
                                        {selectedLocation === cities && (
                                          <CircularProgress
                                            className={classes.btnLoading}
                                          />
                                        )}
                                      </Button>
                                    ))}
                                  </Grid>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    paragraph
                                    variant="caption"
                                    align={"center"}
                                    className={classes.marginBottom0}
                                  >
                                    Currently we only operate in the cities
                                    mentioned above. But we are expanding
                                    quickly and hopeful will reach other areas
                                    soon
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                )}
              <Grid item className={classes.cartIconGrid}>
                <Grid
                  item
                  component={Link}
                  to="/cart"
                  onClick={() => this.setState({ expanded: false })}
                >
                  <div
                    className={classnames(classes.cartItemsCount, {
                      [classes.displayNone]: orderStore.all.size < 1
                    })}
                  >
                    <TypographyPart
                      variant="smallBoldWhite"
                      className={classes.countNumber}
                    >
                      {orderStore.all.size}
                    </TypographyPart>
                  </div>
                  <IconButton
                    className={classes.cartIcon}
                    aria-label="ShoppingCart"
                  >
                    <ShoppingIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.secondRow}>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.noWrap}
            >
              <Grid item>
                <AppBarSecondRowButtonPart to={"/categories"}>
                  <Typography
                    variant="body2"
                    className={classnames(
                      classes.marginLeft1,
                      classes.darkerColor
                    )}
                  >
                    Categories
                  </Typography>
                </AppBarSecondRowButtonPart>
              </Grid>
              <Grid item className={classes.flexGrow1}>
                {pathname !== "/change-location" && pathname !== "/profile" && (
                  <SearchPart />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.locationDropDown}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <AddressesPart
                toggleCollapse={() => toggleExpanded()}
                handleVendorChange={coords => this.handleUpdateLocation(coords)}
              />
            </Collapse>
          </Grid>
        </Grid>
        {uiStore.dataLoaded &&
          (!location || isDefaultLocation) &&
          pathname !== "/change-location" &&
          (isDefaultLocationAllowedPage(pathname) || uiStore.showLocPopup) &&
          pathname !== "/unsubscribe" && (
            <>
              <Grid
                onClick={overlayClicked}
                className={classes.dimBackground}
              />
              {isLoggedIn && <LoadMorePart className={classes.loader} />}
            </>
          )}
        {expanded && (
          <Grid
            className={classes.dimBackground}
            onClick={() => this.setState({ expanded: false })}
          />
        )}
        <DrawerPart open={drawerOpen} onClose={this.handleDrawerClose} />
      </AppBar>
    );
  }
}

export default withRouter(withSnackbar(withCustomStyles(HeaderPart)));
