import { action, autorun, observable, toJS } from "mobx";
import { isHeadless, isLightHouse } from "../utils/AppUtils";
import { loadData, saveData } from "../utils/StoreUtils";
import { DEFAULT_DEBOUNCE } from "../constatns/AppConstants";

class UIStore {
  @observable screenWidth = 360;
  @observable isNotifyPopupShown = false;
  @observable showLocPopup = false;
  @observable subscribeToPush = false;
  @observable locationChanged = false;
  @observable isScrollTopBtn = true;
  @observable dataLoaded = false;
  @observable defaultDeliveryLocation = {
    lat: "31.5204",
    lng: "74.3587",
    location: "Lahore",
    default: true
  };
  @observable availableLocations = [
    {
      lat: "31.5204",
      lng: "74.3587",
      location: "Lahore"
    }
  ];

  constructor() {
    this.persistData();
    if (isHeadless() || isLightHouse()) {
      this.defaultDeliveryLocation = this.availableLocations[0];
    }
  }

  @action setDataLoaded(value = false) {
    this.dataLoaded = value;
  }

  persistData() {
    const key = "uiStore";
    loadData(
      this,
      key,
      data => {
        this.isNotifyPopupShown = data.isNotifyPopupShown;
        this.subscribeToPush = data.subscribeToPush;
        this.isScrollTopBtn = data.isScrollTopBtn;
        if (!isHeadless() || !isLightHouse()) {
          this.defaultDeliveryLocation = data.defaultDeliveryLocation;
        }
        this.dataLoaded = true;
      },
      () => {
        this.dataLoaded = true;
      }
    );
    this.disposeAutorun = autorun(
      () => {
        saveData(this, key, {
          subscribeToPush: toJS(this.subscribeToPush),
          isNotifyPopupShown: toJS(this.isNotifyPopupShown),
          defaultDeliveryLocation: toJS(this.defaultDeliveryLocation),
          isScrollTopBtn: toJS(this.isScrollTopBtn || true)
        });
      },
      { delay: DEFAULT_DEBOUNCE * 8 }
    );
  }

  subscribeToPushNotifications() {
    this.subscribeToPush = true;
    this.isNotifyPopupShown = true;
  }

  dontSubscribeToPushNotifications() {
    this.subscribeToPush = false;
    this.isNotifyPopupShown = true;
  }

  @action
  setScreenWidth(screenWidth) {
    if (isHeadless()) return;
    this.screenWidth = screenWidth;
  }

  @action
  setDefaultDeliveryLocation(location) {
    if (this.defaultDeliveryLocation.location !== location.location) {
      this.locationChanged = true;
    } else {
      this.locationChanged = false;
    }
    this.defaultDeliveryLocation = location;
  }

  @action
  setShowLocPopup(value) {
    this.showLocPopup = value;
  }

  @action
  setScrollUpBtn(value) {
    this.isScrollTopBtn = value;
  }

  extraSmall() {
    return this.screenWidth && this.screenWidth < 330;
  }
}

const uiStore = new UIStore();
export default uiStore;
