import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  root: {
    border: theme.mixins.borderLine2,
    minHeight: "300px"
  },
  largeButton: {
    width: "100%",
    padding: "8px 16px",
    border: theme.mixins.borderLine6,
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  flashTextWrap: {
    maxWidth: "370px"
  },
  bgShape1: {
    backgroundColor: "red",
    width: "46%",
    position: "relative",
    display: "inline-block",
    height: 30,
    "&::before": {
      content: "''",
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "0 0 30px 11px",
      borderColor: "transparent transparent #FED831 transparent",
      right: 0,
      top: 0
    }
  },
  bgShape2: {
    backgroundColor: "#FED831",
    width: "54%",
    position: "relative",
    display: "inline-block",
    height: 30,
    "&::before": {
      content: "''",
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "0 0 30px 11px",
      borderColor: "transparent transparent #ffffff transparent",
      right: 0,
      top: 0
    }
  },
  flashDealIcon: {
    color: "#FED831",
    position: "absolute",
    left: 0,
    top: -7,
    width: 30,
    height: 30
  },
  flashDeal: {
    fontStyle: "italic",
    fontWeight: "400",
    padding: "4px 4px 4px 0px",
    fontSize: "1rem",
    textAlign: "center"
  },
  timerText: {
    color: "#000",
    fontWeight: "400",
    padding: "4px 15px 4px 0px",
    fontSize: "1rem",
    textAlign: "center"
  },
  outOfStock: {
    filter: "grayscale(1)"
  },
  outOfStockSVG: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    width: "150px",
    height: "150px",
    [theme.breakpoints.up("sm")]: {
      width: "250px",
      height: "250px"
    },
    [theme.breakpoints.up("md")]: {
      width: "300px",
      height: "300px"
    },
    opacity: 0.85
  },
  productName: {
    color: theme.palette.text.primary,
    fontSize: "1.25rem",
    fontWeight: "500",
    lineHeight: "1.6"
  },
  cardMediaItem: {
    minHeight: 130,
    [theme.breakpoints.up("sm")]: {
      minHeight: 150
    },
    minWidth: "auto",
    position: "relative"
  },
  cardMedia: {
    width: "100%",
    height: "45vh",
    maxHeight: "600px",
    objectFit: "initial",
    [theme.breakpoints.up("sm")]: {
      height: "55vh",
      maxHeight: "700px"
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      height: "65vh",
      maxHeight: "700px"
    }
  },
  unit: {
    paddingTop: theme.spacing(1)
  },
  price: {
    paddingTop: theme.spacing(2)
  },
  dealFlash: {
    backgroundColor: "red",
    borderRadius: 20,
    display: "inline-block",
    lineHeight: "1",
    textTransform: "uppercase",
    top: "0px",
    left: "0px",
    marginLeft: 10,
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`
  },
  addToCartButton: {
    marginTop: theme.spacing(1),
    "&.item_added": {
      "& h6": {
        fontSize: 17,
        fontWeight: "bold"
      },
      [theme.breakpoints.up("sm")]: {
        maxWidth: 250
      }
    }
  },
  hiddenDeal: {
    visibility: "hidden",
    lineHeight: "1",
    marginTop: "1px",
    position: "relative",
    zIndex: "1",
    top: "0px",
    left: "0px",
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(2)}px`
  },
  nonDealPrice: {
    textDecoration: "line-through",
    marginLeft: theme.spacing(2)
  },
  whyShopHeading: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  textLineHeight: {
    maxHeight: 22,
    marginBottom: 8
  },
  dynamicHtml: {
    marginTop: theme.spacing(1),
    overflow: "auto"
  },
  similarProductsLink: {
    textDecoration: "none",
    marginTop: theme.spacing(1)
  },
  paper: {
    width: "100%",
    height: 230,
    maxWidth: 400,
    margin: 16,
    borderRadius: 5,
    padding: 28,
    [theme.breakpoints.down("sm")]: {
      height: 210,
      padding: 22
    }
  },
  heading: {
    fontSize: 18,
    lineHeight: "30px",
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
      marginBottom: 20
    }
  },
  dialogButton: {
    width: "100%",
    padding: "13px 15px",
    fontSize: 16,
    textTransform: "capitalize",
    boxShadow: "none"
  },
  dialogContent: {
    padding: "0 !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  dialogCloseButton: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 0
  },
  similarProductBtn: {
    textTransform: "capitalize",
    marginTop: 10
  },
  productDetails: {
    "& table": {
      tableLayout: "unset !important",
      width: "100% !important"
    },
    "& *": {
      fontFamily: "Sora, sans-serif !important"
    },
    "& p": {
      fontSize: "16px !important",
      marginBottom: "10px !important",
      "& *": {
        fontSize: "16px !important"
      }
    },
    "& ul": {
      fontSize: "16px !important",
      marginBottom: "10px !important",
      "& *": {
        fontSize: "16px !important"
      }
    }
  }
}));

export default withCustomStyles;
