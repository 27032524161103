import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withCustomStyles from "./AddressesPart.style";
import AddIcon from "@material-ui/icons/Add";
import { withRouter } from "react-router";
import * as classnames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Paper from "@material-ui/core/Paper";
import { inject, observer } from "mobx-react";
import { withSnackbar } from "notistack";
import { strLimit } from "../utils/HelperUtils";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import _throttle from "lodash/throttle";
import gtmService from "../services/GTMService";

@inject(["locationStore"])
@inject(["customerStore"])
@observer
class AddressesPart extends React.Component {
  state = {
    selectClicked: false
  };

  handleSelectAddress = _throttle(
    event => {
      const {
        props: {
          location: { pathname },
          history,
          locationStore,
          enqueueSnackbar,
          toggleCollapse,
          handleVendorChange,
          closeModal,
          isModal
        }
      } = this;
      if (event.target.value == locationStore.currentAddress.id) {
        if (isModal) {
          closeModal();
        } else {
          toggleCollapse();
        }
        return;
      }
      this.setState({ selectClicked: true });
      locationStore.selectAddress(
        event.target.value,
        data => {
          gtmService.event(
            "User",
            "Location_selected_from_location_bottom_sheet",
            pathname === "/checkout" ? "From_Checkout_Page" : "From_Home_Page"
          );
          if (isModal) {
            closeModal();
          } else {
            toggleCollapse();
          }
          if (data.vendor_changed) {
            const coords = {
              lat: data.address.location_coordinates.split(",")[0],
              lng: data.address.location_coordinates.split(",")[1],
              location: data.address.pin_address
            };
            handleVendorChange(coords);
          }
          if (data.express_changed && pathname === "/checkout") {
            history.push("/cart");
          }
        },
        () => {
          enqueueSnackbar("Something went wrong, please try again", {
            variant: "error"
          });
        }
      );
    },
    10000,
    { trailing: false }
  );

  addNewAddress = () => {
    const {
      props: {
        history,
        toggleCollapse,
        isModal,
        location: { pathname }
      }
    } = this;
    if (!isModal) {
      toggleCollapse();
    }
    if (pathname === "/checkout" || pathname === "/cart") {
      gtmService.event(
        "User",
        "Add_Address_from_bottom_sheet",
        "From_Checkout_Page"
      );
      history.push("/add-address?redirect=/cart");
    } else {
      gtmService.event(
        "User",
        "Add_Address_from_bottom_sheet",
        "From_Home_Page"
      );
      history.push("/add-address");
    }
  };

  componentWillUnmount() {
    const {
      state: { selectClicked },
      props: { locationStore }
    } = this;
    if (selectClicked) {
      locationStore.getAddresses();
    }
  }

  render() {
    const {
      props: { classes, isModal, locationStore, closeModal },
      handleSelectAddress
    } = this;

    return (
      <Paper
        className={classnames(
          classes.dropDownSection,
          classes.relativePosition,
          {
            [classes.modalPadding]: isModal
          }
        )}
      >
        <Grid
          container
          direction="column"
          justify="space-between"
          alignContent="center"
        >
          <Grid
            item
            className={classnames(classes.gutterBottom2, classes.gutterTopHalf)}
          >
            <Typography
              variant={"body2"}
              className={classnames(classes.deliveryAddress, classes.fontSize2)}
            >
              Where should we deliver your order?
            </Typography>
            {isModal && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={closeModal}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
          <FormControl
            component="fieldset"
            className={classnames(classes.fullWidth, classes.gutterBottom2)}
          >
            <FormLabel component="legend" className={classes.gutterBottom2}>
              <Typography variant={"body2"} className={classes.boldFont}>
                Saved Locations
              </Typography>
            </FormLabel>
            <RadioGroup
              aria-label="Address Option"
              value={locationStore.currentAddress}
              onChange={handleSelectAddress}
            >
              <Grid container direction="column" justify="space-between">
                {locationStore.all.map(location => (
                  <Grid item className={classes.addressList} key={location.id}>
                    <FormControlLabel
                      value={location.id}
                      checked={!!location.is_selected}
                      control={<Radio color={"primary"} />}
                      label={
                        <Grid item className={classes.labelText}>
                          <Typography
                            variant={"h4"}
                            className={classes.deliveryAddress}
                          >
                            {strLimit(location.label, 15)}
                          </Typography>
                          <Typography
                            variant={"body2"}
                            className={classes.hyphenateText}
                          >
                            {locationStore.currentAddress &&
                              strLimit(location.single_line_address, 30)}
                          </Typography>
                        </Grid>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
          <Grid
            className={classnames(
              classes.hoverUnderlineAnimation,
              classes.enterNewAddress,
              classes.borderLine3Top
            )}
            onClick={() => this.addNewAddress()}
          >
            <Grid container direction="row" alignItems="flex-start">
              <AddIcon className={classes.marginRight1} />
              <Typography>Add new location</Typography>
            </Grid>
            <Grid />
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

export default withRouter(withSnackbar(withCustomStyles(AddressesPart)));
