import React, { Component } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router } from "react-router-dom";
import MainTheme from "./theme/MainTheme";
import withCustomStyles from "./App.style";
import MainWrapper from "./MainWrapper";
import { SnackbarProvider } from "notistack";
import Button from "@material-ui/core/Button";

class App extends Component {
  notistackRef = React.createRef();

  render() {
    return (
      <ThemeProvider theme={MainTheme}>
        <Router>
          <SnackbarProvider
            maxSnack={1}
            ref={this.notistackRef}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            action={key => (
              <Button
                className={this.props.classes.notistackText}
                onClick={() =>
                  this.notistackRef.current.handleDismissSnack(key)
                }
              >
                Dismiss
              </Button>
            )}
            classes={{
              variantInfo: this.props.classes.info
            }}
          >
            <MainWrapper />
          </SnackbarProvider>
        </Router>
      </ThemeProvider>
    );
  }
}

export default withCustomStyles(App);
