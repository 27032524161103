import React from "react";
import withCustomStyles from "./OrderDetailPart.style";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TypographyPart from "./TypographyPart";
import classnames from "classnames";
import { withRouter } from "react-router";
import LoadMorePart from "./LoadMorePart";
import { inject, observer } from "mobx-react";
import { getImage } from "../utils/UrlUtils";
import {
  dealPrice,
  isDeal,
  productActivePrice,
  productName,
  productPrice,
  isFlashDeal
} from "../utils/ProductUtils";
import ImageWithLoaderPart from "./ImageWithLoaderPart";

@inject(["orderStore"])
@inject(["productStore"])
@observer
class OrderDetailPart extends React.Component {
  render() {
    const {
      props: { classes, product, nameCharLimit, variant }
    } = this;

    const getPrice = product => {
      if (isDeal(product) || isFlashDeal(product)) {
        return (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.textLineHeight}
          >
            <Grid item>
              <Typography variant="subtitle2">
                Rs. {dealPrice(product)}
              </Typography>
            </Grid>
            <Grid item className={classes.marginLeft1}>
              <Typography
                variant="caption"
                className={classnames(classes.nonDealPrice)}
              >
                Rs. {productPrice(product)}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      return (
        <Typography variant="subtitle2">Rs. {productPrice(product)}</Typography>
      );
    };

    return (
      <Card className={classes.card}>
        {!product.name && <LoadMorePart />}
        {product.name && (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item className={classes.cardMediaItem} sm={2} xs={2}>
              <ImageWithLoaderPart
                width="60"
                height="60"
                alt={product.name}
                src={getImage(product)}
                loaderClass={classes.marginLeft1}
                className={classes.cardMedia}
              />
            </Grid>
            <Grid item xs className={classes.marginLeft2}>
              <Grid
                container
                direction="column"
                justify="flex-end"
                alignItems="stretch"
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item xs>
                      <Typography
                        variant="body1"
                        className={classes.fontAdjust}
                      >
                        {productName(product.name, nameCharLimit)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid className={classnames(classes.gutterBottom1)} item>
                  <Typography variant="caption">{product.unit}</Typography>
                </Grid>
                {variant === "found" && (
                  <React.Fragment>
                    <Grid className={classnames(classes.gutterBottom1)} item>
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="baseline"
                      >
                        <Grid
                          item
                          className={classnames(classes.displayInline)}
                        >
                          {getPrice(product)}
                          <Typography
                            className={classnames(classes.multiply)}
                            variant={"caption"}
                          >
                            &#215;
                          </Typography>
                          <Typography variant={"subtitle2"}>
                            {product.quantity}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="baseline"
                      >
                        <Grid item className={classes.priceDealPart}>
                          <Typography variant={"subtitle2"}>
                            Rs. {product.quantity * productActivePrice(product)}
                          </Typography>
                          <TypographyPart
                            variant="smallBoldWhite"
                            className={classnames({
                              [classes.dealFlash]:
                                isDeal(product) || isFlashDeal(product)
                            })}
                          >
                            {isFlashDeal(product)
                              ? product.flash_deal.title
                              : product.deal_title}
                          </TypographyPart>
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
    );
  }
}

OrderDetailPart.propTypes = {
  product: PropTypes.object.isRequired,
  isHorizontal: PropTypes.bool,
  nameCharLimit: PropTypes.number,
  variant: PropTypes.string
};
OrderDetailPart.defaultProps = {
  isHorizontal: false,
  nameCharLimit: 80,
  variant: "found"
};

export default withRouter(withCustomStyles(OrderDetailPart));
