import { action, autorun, computed, observable, toJS } from "mobx";
import { loadData, saveData } from "../utils/StoreUtils";
import { DEFAULT_DEBOUNCE } from "../constatns/AppConstants";
import { getRequest, postRequest } from "../utils/RestMiddlewareUtil";
import customerStore from "./CustomerStore";
import { isHeadless, logInfoMessage } from "../utils/AppUtils";
import { fetchRemoteConfig } from "../services/FirebaseService";

class GlobalSettingStore {
  @observable deviceId = "";
  @observable cleverTapId = "";
  @observable refferFriendBanner = "";
  @observable UANNUMBER = "";
  @observable state = "pending";
  @observable settingState = "pending";
  @observable settings = {
    wallet_order_limit: 500
  };

  platform = "web";
  osVersion = "";
  deviceModel = "";
  language = "en";

  promise;
  disposeAutorun;

  constructor() {
    this.setDeviceInfo();
    this.persistData();
    this.setGlobalConst();
  }

  fetch = () => {
    this.fetchInternal();
  };

  @computed get vendorId() {
    return customerStore.vendorId;
  }

  @action
  setDeviceInfo() {
    this.osVersion = window.navigator.platform;
    this.deviceModel = window.navigator.userAgent;
  }

  @action
  getCleverTapID() {
    if (window.clevertap && window.clevertap.getCleverTapID) {
      return window.clevertap.getCleverTapID();
    } else {
      logInfoMessage(
        "Clevertap ID not set:" + (navigator && navigator.userAgent)
      );
    }
  }

  persistData() {
    const key = "globalSettingStore";
    loadData(this, key, data => {
      this.settings = data.settings || {};
      this.deviceId = data.deviceId;
      this.refferFriendBanner = data.refferFriendBanner;
      this.UANNUMBER = data.UANNUMBER;
    });
    this.disposeAutorun = autorun(
      () => {
        saveData(this, key, {
          settings: toJS(this.settings || {}),
          deviceId: this.deviceId,
          refferFriendBanner: this.refferFriendBanner,
          UANNUMBER: this.UANNUMBER
        });
      },
      { delay: DEFAULT_DEBOUNCE }
    );
  }

  @action
  cancel() {
    this.state = "pending";
    if (this.promise) {
      this.promise.cancel();
    }
  }

  @action
  cancelPromise(callee) {
    if (this[callee + "Promise"]) {
      this[callee + "Promise"].cancel();
    }
    this[callee + "State"] = "pending";
  }

  fetchInternal = () => {
    this.cancelPromise("setting");

    this.settingState = "fetching";
    this.settingPromise = getRequest(
      this,
      "v1/global/settings",
      data => {
        const setting = {};
        data.map(sData => (setting[sData.key] = sData.value));
        this.settings = setting;
        this.settingState = "done";
      },
      () => (this.settingState = "fail"),
      () => (this.settingState = "error")
    );
  };

  registerDevice = (success = () => {}, fail = () => {}) => {
    if (!this.registerDeviceState || this.registerDeviceState !== "pending") {
      if (
        window.clevertap &&
        window.clevertap.getCleverTapID &&
        !isHeadless()
      ) {
        this.cancelPromise("registerDevice");
        this.cleverTapId = window.clevertap.getCleverTapID();
        const body = {
          platform: this.platform,
          device_model: this.deviceModel,
          clevertap_id: window.clevertap.getCleverTapID(),
          language: this.language,
          vendor_id: this.vendorId,
          build_version: process.env.REACT_APP_COMMIT_REF,
          os_version: this.osVersion
        };

        postRequest(
          this,
          "v2/user/device",
          body,
          data => {
            this.deviceId = data.device_request_key;
            success(data.device_request_key);
            this.registerDeviceState = "done";
          },
          () => (this.registerDeviceState = "fail"),
          () => (this.registerDeviceState = "error")
        );
      } else {
        fail();
      }
    }
  };

  setGlobalConst = () => {
    fetchRemoteConfig().then(config => {
      this.refferFriendBanner = config["subscription_slider"]._value;
      this.UANNUMBER = config["customer_support_phone"]._value;
    });
  };

  cancelAndDisposeAutorun() {
    this.cancel();
    this.disposeAutorun();
  }
}

const globalSettingStore = new GlobalSettingStore();
export default globalSettingStore;
