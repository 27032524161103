import React, { Component } from "react";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Paper from "@material-ui/core/Paper";
import { withRouter } from "react-router";
import withCustomStyles from "./DrawerPart.style";
import ReceiptOutlined from "@material-ui/icons/ReceiptOutlined";
import PermIdentityRounded from "@material-ui/icons/PermIdentityRounded";
import PhoneCallbackOutlined from "@material-ui/icons/PhoneCallbackOutlined";
import PowerSettingsNewRounded from "@material-ui/icons/PowerSettingsNewRounded";
import AccountBalanceWalletOutlined from "@material-ui/icons/AccountBalanceWalletOutlined";
import CardMembership from "@material-ui/icons/CardMembership";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import Chip from "@material-ui/core/Chip";
import { isEasyPaisaMiniApp, UANNUMBER } from "../utils/AppUtils";
import LiveHelpOutlined from "@material-ui/icons/LiveHelpOutlined";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import LocationOutlined from "@material-ui/icons/LocationOnOutlined";
import { ReactComponent as DiscountMenuIcon } from "../assets/images/discount-menu-icon.svg";
import { ReactComponent as AboutUsIcon } from "../assets/images/about-us-menu-icon.svg";
import { ZendeskAPI } from "react-zendesk";

@inject(["customerStore"])
@observer
class DrawerPart extends Component {
  state = {
    drawerOpen: true
  };

  componentDidMount() {
    this.props.customerStore.fetchCustomerInfo();
  }

  handleSignoutClick = () => {
    const {
      props: { customerStore, onClose }
    } = this;
    onClose();
    if (customerStore.isLoggedIn) {
      customerStore.logout();
    }
  };

  signinLink = () => {
    const {
      props: { customerStore }
    } = this;
    return customerStore.isLoggedIn ? "" : "/login";
  };

  handleLiveChat = () => {
    this.props.onClose();
    if ("zE" in window) {
      ZendeskAPI("webWidget", "show");
      ZendeskAPI("webWidget", "open");
    }
  };

  render() {
    const {
      props: {
        open,
        classes,
        customerStore,
        onClose,
        location: { pathname }
      },
      handleLiveChat
    } = this;

    return (
      <Drawer open={open} onClose={onClose} className={classes.drawer}>
        <Paper className={classes.drawerChild}>
          <Typography variant={"h6"} gutterBottom className={classes.name}>
            Salam, {customerStore.name}!
          </Typography>
          <List>
            {((isEasyPaisaMiniApp() && customerStore.isLoggedIn) ||
              !isEasyPaisaMiniApp()) && (
              <>
                <ListItem
                  className={classes.listItem}
                  onClick={onClose}
                  component={Link}
                  to={`/profile?redirect=${pathname}`}
                  disableGutters
                >
                  <ListItemIcon>
                    <PermIdentityRounded />
                  </ListItemIcon>
                  <ListItemText primary="My Profile" />
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  onClick={onClose}
                  component={Link}
                  to={"/myorders"}
                  disableGutters
                >
                  <ListItemIcon>
                    <ReceiptOutlined />
                  </ListItemIcon>
                  <ListItemText primary="My Orders" />
                </ListItem>
              </>
            )}
            {customerStore.isLoggedIn && (
              <ListItem
                className={classes.listItem}
                onClick={onClose}
                component={Link}
                to={"/saved-locations"}
                disableGutters
              >
                <ListItemIcon>
                  <LocationOutlined />
                </ListItemIcon>
                <ListItemText primary="Saved Locations" />
              </ListItem>
            )}
            {!isEasyPaisaMiniApp() && (
              <>
                <ListItem
                  className={classes.listItem}
                  onClick={onClose}
                  component={Link}
                  to={"/membership"}
                  disableGutters
                >
                  <ListItemIcon>
                    <CardMembership />
                  </ListItemIcon>
                  <ListItemText primary="GrocerClub" />
                </ListItem>
                {customerStore.isLoggedIn && (
                  <ListItem
                    className={classes.listItem}
                    onClick={onClose}
                    component={Link}
                    to={"/wallet"}
                    disableGutters
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletOutlined />
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                    {customerStore.customer && (
                      <Chip
                        color="primary"
                        label={`PKR ${customerStore.wallet.amount || 0}`}
                      />
                    )}
                  </ListItem>
                )}
                <ListItem
                  className={classes.listItem}
                  onClick={onClose}
                  component={Link}
                  to={"/promo-and-discounts/"}
                  disableGutters
                >
                  <ListItemIcon>
                    <DiscountMenuIcon className={classes.marginLeftHalf} />
                  </ListItemIcon>
                  <ListItemText primary="Discounts and Promos" />
                </ListItem>
                <ListItem
                  onClick={handleLiveChat}
                  className={classes.listItem}
                  disableGutters
                >
                  <ListItemIcon>
                    <ChatOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Live Chat" />
                </ListItem>
                <ListItem
                  className={classes.listItem}
                  component="a"
                  href={`tel: ${UANNUMBER}`}
                  disableGutters
                >
                  <ListItemIcon>
                    <PhoneCallbackOutlined />
                  </ListItemIcon>
                  <ListItemText primary="Call Us" />
                </ListItem>
              </>
            )}
            {isEasyPaisaMiniApp() && (
              <ListItem className={classes.listItem} disableGutters>
                <ListItemIcon>
                  <PhoneCallbackOutlined />
                </ListItemIcon>
                <ListItemText primary={`tel: ${UANNUMBER}`} />
              </ListItem>
            )}
            <ListItem
              className={classes.listItem}
              onClick={onClose}
              component={Link}
              to={"/faqs"}
              disableGutters
            >
              <ListItemIcon>
                <LiveHelpOutlined />
              </ListItemIcon>
              <ListItemText primary="FAQs" />
            </ListItem>
            {!isEasyPaisaMiniApp() && (
              <ListItem
                className={classes.listItem}
                component={Link}
                onClick={onClose}
                to={"/mobile-app/"}
                disableGutters
              >
                <ListItemIcon>
                  <PhoneAndroid />
                </ListItemIcon>
                <ListItemText primary="Mobile App" />
              </ListItem>
            )}
            <ListItem
              className={classes.listItem}
              onClick={onClose}
              component={Link}
              to={"/about-us/"}
              disableGutters
            >
              <ListItemIcon>
                <AboutUsIcon className={classes.marginLeftHalf} />
              </ListItemIcon>
              <ListItemText primary="About Us" />
            </ListItem>
            {!isEasyPaisaMiniApp() && (
              <ListItem
                className={classes.listItem}
                component={Link}
                to={this.signinLink()}
                onClick={this.handleSignoutClick}
                disableGutters
              >
                <ListItemIcon>
                  <PowerSettingsNewRounded />
                </ListItemIcon>
                <ListItemText
                  primary={customerStore.isLoggedIn ? "Sign Out" : "Sign In"}
                />
              </ListItem>
            )}
          </List>
        </Paper>
      </Drawer>
    );
  }
}

export default withRouter(withCustomStyles(DrawerPart));
