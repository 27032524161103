import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(() => ({
  loaderImg: {
    width: "55%",
    maxWidth: 400,
    position: "absolute",
    top: -100,
    bottom: 0,
    left: 0,
    right: 0,
    margin: "auto"
  },
  loaderWrap: {
    position: "fixed",
    backgroundColor: "#fff",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 99999
  }
}));

export default withCustomStyles;
