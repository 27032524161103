import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  appBar: {
    zIndex: 11,
    width: "100%",
    maxWidth: "100%",
    padding: "0px 0px 6px 0px",
    backgroundColor: theme.palette.common.white
  },
  secondRow: {
    paddingLeft: theme.spacing(2)
  },
  searchContainer: {
    padding: theme.spacing(1),
    flex: 1
  },
  cart: {
    minWidth: 56,
    minHeight: 48
  },
  logo: {
    width: theme.spacing(6.5),
    height: theme.spacing(6),
    borderRadius: theme.spacing(4)
  },
  cartIcon: {
    color: theme.palette.common.black,
    marginRight: theme.spacing(1)
  },
  cartItemsCount: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20,
    padding: 5,
    position: "absolute",
    zIndex: 1,
    right: theme.spacing(1),
    top: 5,
    minWidth: 24
  },
  countNumber: {
    textAlign: "center"
  },
  mapMarker: {
    marginRight: theme.spacing(1),
    marginBottom: -2
  },
  deliveryAddress: {
    color: theme.palette.common.black,
    fontWeight: 600
  },
  popperUi: {
    zIndex: 99999,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: "300px",
    maxWidth: "520px",
    top: "60px",
    borderRadius: theme.spacing(1)
  },
  locationModalWrap: {
    padding: "15px 24px 24px"
  },
  dimBackground: {
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundColor: theme.palette.common.black,
    top: 0,
    left: 0,
    opacity: 0.3,
    zIndex: 2
  },
  paddingRight1: {
    paddingRight: theme.spacing(1)
  },
  marginBottom0: {
    marginBottom: 0
  },
  paddingRight2: {
    paddingRight: theme.spacing(2)
  },
  flexGrow1: {
    flexGrow: 1,
    marginRight: theme.spacing(1)
  },
  cartIconGrid: {
    position: "relative"
  },
  btnLoading: {
    position: "absolute",
    width: "20px !important",
    height: "20px !important"
  },
  addressBox: {
    textDecoration: "none",
    cursor: "pointer"
  },
  locationDropDown: {
    maxWidth: 450,
    maxHeight: 450,
    [theme.breakpoints.up(600)]: {
      left: 100
    },
    top: 52,
    zIndex: 9999,
    marginBottom: 0,
    marginTop: 6,
    position: "absolute",
    overflow: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: theme.spacing(1.2),
      height: theme.spacing(1.2),
      borderRadius: theme.spacing(2.5),
      backgroundColor: "#F5F5F5"
    },
    "&::-webkit-scrollbar-track": {
      background: "#F5F5F5",
      borderRadius: theme.spacing(1.2)
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#9c9c9c",
      borderRadius: theme.spacing(2.5)
    }
  },
  loader: {
    top: "45vh",
    position: "absolute",
    zIndex: 2
  },
  zIndexBar: {
    zIndex: 9999,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: theme.spacing(1)
  },
  rotate: {
    transform: "rotate(0deg)",
    transition: "all .3s ease-in-out",
    marginBottom: -6
  },
  rotateBack: {
    transform: "rotate(180deg)",
    transition: "all .3s ease-in-out",
    marginBottom: -6
  },
  deliveryBtn: {
    padding: "6px 12px"
  }
}));

export default withCustomStyles;
