import withSharedStyles from "../theme/Shared.style";
const withCustomStyles = withSharedStyles(theme => ({
  card: {
    height: "100%",
    boxShadow: "none"
  },
  cardMedia: {
    marginLeft: "calc(100% - 60px)",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "calc(100% - 80px)"
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: "calc(100% - 110px)"
    },
    height: 70,
    width: 70
  },
  cardMediaItem: {
    height: 60,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1)
  },
  priceDealPart: {
    display: "flex",
    justifyContent: "space-between",
    width: 140
  },
  dealFlash: {
    backgroundColor: "red",
    borderRadius: 20,
    display: "inline-block",
    lineHeight: "1",
    textTransform: "uppercase",
    top: "0px",
    left: "0px",
    padding: "5px 8px"
  },
  nonDealPrice: {
    textDecoration: "line-through"
  },
  multiply: {
    margin: "2px 8px 0px 8px"
  },
  fontAdjust: {
    fontSize: "1rem",
    [theme.breakpoints.down(600)]: {
      fontSize: "0.9rem"
    }
  }
}));
export default withCustomStyles;
