import React from "react";
import { withRouter } from "react-router";
import withCustomStyles from "./FullPageLoaderPart.style";

const FullPageLoaderPart = props => {
  const { classes } = props;

  return (
    <div className={classes.loaderWrap}>
      <img
        alt="GrocerApp"
        src="https://d33wubrfki0l68.cloudfront.net/79a4affb366a259d32bd25a7ce33d489980de84f/458ed/image/launcher_loader.svg"
        className={classes.loaderImg}
      />
    </div>
  );
};

export default withRouter(withCustomStyles(FullPageLoaderPart));
