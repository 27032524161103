import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  lastOrder: {
    paddingTop: theme.spacing(1)
  },
  mobileOnly: {
    [theme.breakpoints.up("sm")]: {
      display: "none !important"
    }
  },
  memberBannerImg: {
    width: "100%",
    height: "auto"
  }
}));

export default withCustomStyles;
