import withSharedStyles from "../theme/Shared.style";

// TODO: Need to fix the root css if possible
const withCustomStyles = withSharedStyles(theme => ({
  root: {
    "& > div > div": {
      "&::-webkit-scrollbar": {
        width: theme.spacing(1.2),
        height: theme.spacing(1.2),
        borderRadius: theme.spacing(2.5),
        backgroundColor: "#F5F5F5"
      },
      "&::-webkit-scrollbar-track": {
        background: "#F5F5F5",
        borderRadius: theme.spacing(1.2)
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#9c9c9c",
        borderRadius: theme.spacing(2.5)
      },
      margin: theme.spacing(1),
      padding: theme.spacing(2)
    },
    zIndex: 13
  },
  modalBox: {
    boxShadow: "0 12px 15px 0 rgba(0,0,0,.25)",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  button: {
    display: "inline-flex",
    justifyContent: "flex-end",
    width: "100%",
    "& button": {
      margin: `0 ${theme.spacing(0.6)}px`,
      padding: `${theme.spacing(1.2)}px ${theme.spacing(1.3)}px`
    }
  },
  redButton: {
    backgroundColor: theme.palette.common.red,
    color: "white"
  },
  greyButton: {
    filter: "grayscale(100%)"
  }
}));

export default withCustomStyles;
