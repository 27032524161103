import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  catSection: {
    [theme.breakpoints.up(365)]: {
      maxWidth: "50vw"
    }
  },
  linkWrap: {
    display: "flex"
  },
  categoryLink: {
    marginLeft: theme.spacing(0.3)
  }
}));

export default withCustomStyles;
