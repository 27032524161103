import { action, autorun, computed, observable, toJS } from "mobx";
import { getRequest } from "../utils/RestMiddlewareUtil";
import { pushCategoryNode } from "../utils/ProductUtils";
import { objectFilter } from "../utils/HelperUtils";
import customerStore from "./CustomerStore";
import { loadData, saveData } from "../utils/StoreUtils";
import { DEFAULT_DEBOUNCE } from "../constatns/AppConstants";
import { logInfoMessage } from "../utils/AppUtils";

class CategoriesStore {
  @observable all = [];
  @observable flat = [];
  @observable youngPeopleBuy = [];
  @observable state = "pending";
  @observable categoriesBrandsState = "pending";
  @observable categoriesBrands = [];
  @observable categoriesBrandSeo = [];

  promise;

  constructor() {
    this.persistData();
  }

  persistData() {
    const key = "categoriesStore";
    loadData(this, key, data => {
      this.all = data.all;
      this.flat = data.flat;
      this.categoriesBrands = data.categoriesBrands || [];
      this.categoriesBrandSeo = data.categoriesBrandSeo || [];
    });
    this.disposeAutorun = autorun(
      () => {
        saveData(this, key, {
          all: toJS(this.all),
          flat: toJS(this.flat),
          categoriesBrands: toJS(this.categoriesBrands),
          categoriesBrandSeo: toJS(this.categoriesBrandSeo)
        });
      },
      { delay: DEFAULT_DEBOUNCE }
    );
  }

  @action
  cancelAndDisposeAutorun() {
    this.cancelPromise();
    this.disposeAutorun();
  }

  @computed get vendorId() {
    return customerStore.vendorId;
  }

  getCategoryBrandSEO(brandSlug) {
    let x = {};
    this.categoriesBrands.forEach(brand => {
      if (brand.slug == brandSlug) {
        let brandId = brand.id;
        x = this.categoriesBrandSeo[brandId];
      }
    });
    return x || {};
  }

  @action
  cancelPromise(callee) {
    if (this[callee + "Promise"]) {
      this[callee + "Promise"].cancel();
    }
    this[callee + "State"] = "pending";
  }

  @action
  fetch = () => {
    this.cancelPromise("fetch");
    this.fetchPromise = getRequest(
      this,
      `v2/categories/list?vendor_id=${this.vendorId}`,
      data => {
        this.all = data.tree;
        this.flat = data.flat;
        this.youngPeopleBuy = [
          this.flat[21],
          this.flat[42],
          this.flat[43],
          this.flat[44]
        ];
      }
    );
  };

  @action
  fetchCategoryBrands = (categoryId, error = () => {}) => {
    this.cancelPromise("fetchCategoryBrands");
    this.categoriesBrandsState = "fetching";
    this.fetchCategoryBrandsPromise = getRequest(
      this,
      `v1/categories/brand?vendor_id=${this.vendorId}&category_id=${categoryId}`,
      data => {
        this.categoriesBrands = data.brands;
        this.categoriesBrandSeo = data.seo;
        this.categoriesBrandsState = "done";
      },
      () => {
        this.categoriesBrands = [];
        this.categoriesBrandSeo = [];
        this.categoriesBrandsState = "fail";
      },
      e => {
        this.categoriesBrands = [];
        this.categoriesBrandSeo = [];
        this.categoriesBrandsState = "error";
        error(e);
      }
    );
  };

  @action
  cancel() {
    this.state = "pending";
    if (this.promise) {
      this.promise.cancel();
    }
    this.cancelPromise("fetch");
  }

  @action
  getCidBySlug(slug) {
    let currentCategory = objectFilter(
      this.flat,
      category => category.slug_url === slug
    )[0];

    if (!currentCategory && slug) {
      logInfoMessage("Category Id not found", {
        data: this.flat
      });
      if (!localStorage.getItem("indexDB-refresh")) {
        this.flat = {};
        this.all = [];
        localStorage.setItem("indexDB-refresh", true);
        window.location.reload();
      }
    }

    let intId = (currentCategory && currentCategory.id) || null;
    if (intId === 145 || intId === 99 || intId === 83) {
      intId--;
    }
    return intId;
  }

  children(id) {
    let intId = parseInt(id);
    if (intId === 144 || intId === 98 || intId === 82) {
      intId++;
    }
    return (
      objectFilter(this.flat, category => category.parent_id === intId) || []
    );
  }

  parents(id) {
    let node = this.flat[id];
    if (!node) {
      return [];
    }
    return [...this.parents(node.parent_id), ...pushCategoryNode(node)];
  }

  find(id) {
    return this.flat[id] || {};
  }
}

const categoriesStore = new CategoriesStore();
export default categoriesStore;
