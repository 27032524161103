import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  card: {
    height: "100%",
    border: theme.mixins.borderLine2,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  mainRow: {
    borderBottom: theme.mixins.borderLine2,
    padding: theme.spacing(1)
  },
  priceRow: {
    borderBottom: theme.mixins.borderLine2,
    padding: "8px 8px 0px 8px"
  },
  timer: {
    marginRight: theme.spacing(1) / 2,
    marginTop: theme.spacing(1) / 4
  },
  green: {
    backgroundColor: theme.palette.common.green
  },
  red: {
    backgroundColor: theme.palette.error.main
  },
  main: {
    backgroundColor: theme.palette.primary.main
  },
  doneIcon: {
    color: "white",
    borderRadius: theme.spacing(2)
  },
  newIcon: {
    color: "white",
    borderRadius: theme.spacing(1)
  },
  reorder: {
    padding: "5px 8px"
  },
  retryMain: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1.25),
    "& button": {
      minWidth: "max-content"
    }
  },
  rotate: {
    transform: "rotate(0deg)",
    transition: "all .3s ease-in-out"
  },
  rotateBack: {
    transform: "rotate(180deg)",
    transition: "all .3s ease-in-out"
  },
  pointer: {
    cursor: "pointer",
    height: 68,
    marginTop: -5,
    [theme.breakpoints.up("sm")]: {
      height: 40
    },
    "&:hover": {
      transition: "all .3s ease-in-out",
      backgroundColor: "rgba(0,0,0,.05)"
    }
  },
  retry: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: "relative",
    width: "100%",
    height: "inherit",
    backgroundColor: "transparent"
  },
  otherCharges: {
    paddingLeft: 10,
    paddingTop: 10
  },
  timerSection: {
    justifyContent: "flex-start",
    marginTop: 8,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      justifyContent: "flex-end"
    }
  },
  expandSection: {
    justifyContent: "flex-end",
    position: "relative",
    top: 14,
    [theme.breakpoints.up("sm")]: {
      top: 0,
      justifyContent: "center"
    }
  }
}));

export default withCustomStyles;
