import React from "react";
import withCustomStyles from "./ProductTilePart.style";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TypographyPart from "../parts/TypographyPart";
import classnames from "classnames";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import { ReactComponent as OutOfStock } from "../assets/images/out_of_stock.svg";
import { getImage, getProductPageUrl } from "../utils/UrlUtils";
import { timeDiffBreakdown } from "../utils/DateUtils";
import {
  dealPrice,
  isDeal,
  isFlashDeal,
  productName,
  productPrice,
  remindMe,
  hasFlashDealStarted,
  isFlashDealExpired
} from "../utils/ProductUtils";
import { selectWebOrMobile } from "../utils/HelperUtils";
import ImageWithLoaderPart from "./ImageWithLoaderPart";
import AddToCartButtonPart from "./AddToCartButtonPart";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import TimerPart from "./TimerPart";
import { Link } from "react-router-dom";
import { isHeadless } from "../utils/AppUtils";
import gtmService from "../services/GTMService";
import { getCategoryName } from "../utils/CategoryUtils";
import { forceCheck } from "react-lazyload";
import { lazyLoadComponentInViewPort } from "../utils/lazyLoadComponent";
import FlashOnIcon from "@material-ui/icons/FlashOn";

@inject(["dealsStore"])
@inject(["productStore"])
@inject(["productsStore"])
@inject(["customerStore"])
@inject(["myOrdersStore"])
@inject(["featuredProductsStore"])
@inject(["userBasedRecommendationsStore"])
@observer
class ProductTilePart extends React.Component {
  componentDidMount() {
    forceCheck();
  }

  handleProductClick = product => {
    this.props.productStore.setProduct(product);
  };

  handleTileClick = () => {
    const {
      props: { product, listName, CTListName }
    } = this;

    if (CTListName && CTListName.split(" - ")[0] === "Horizontal Section") {
      gtmService.pageView("/horizontal-product-click", {
        name: CTListName.split(" - ")[1],
        "category id": product.category_id.toString(),
        "category name": getCategoryName(product.category_id)
      });
    }
    gtmService.productClick(product, listName);
  };

  refreshProduct = product => {
    const {
      props: {
        dealsStore,
        productsStore,
        myOrdersStore,
        featuredProductsStore,
        userBasedRecommendationsStore
      }
    } = this;
    [
      myOrdersStore.previouslyOrderedList,
      dealsStore.all,
      featuredProductsStore.all,
      userBasedRecommendationsStore.all,
      productsStore.all,
      productsStore.daalainAndRice,
      productsStore.fruitsAndVegetables
    ].forEach(arr => {
      arr.find(prd => {
        if (prd.id === product.id && prd.flash_deal) {
          prd.flash_deal.status =
            prd.flash_deal.status === 1
              ? 2
              : prd.flash_deal.status === 2
              ? 0
              : prd.flash_deal.status;
          return;
        }
      });
    });
  };

  render() {
    const {
      props: {
        classes,
        product,
        productStore,
        isHorizontal,
        nameCharLimit,
        listName,
        CTListName,
        state,
        query
      }
    } = this;

    const getPrice = product => {
      if (
        isFlashDeal(product) ||
        isDeal(product) ||
        !isFlashDealExpired(product)
      ) {
        return (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
            className={classes.textLineHeight}
          >
            <Grid item>
              <Typography
                variant="subtitle2"
                className={{ [classes.priceText]: isFlashDeal(product) }}
              >
                Rs. {dealPrice(product)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.nonDealPrice}>
                Rs. {productPrice(product)}
              </Typography>
            </Grid>
            {!isHorizontal && (
              <Grid item className={classes.discountHeight}>
                <TypographyPart
                  variant="smallBoldWhite"
                  className={classnames({
                    [classes.dealFlash]:
                      isDeal(product) || isFlashDeal(product),
                    [classes.hiddenDeal]:
                      !isDeal(product) && !isFlashDeal(product),
                    [classes.marginLeftHalf]: !isHorizontal
                  })}
                >
                  {isFlashDeal(product)
                    ? product.flash_deal.title
                    : product.deal_title}
                </TypographyPart>
              </Grid>
            )}
          </Grid>
        );
      }
      return (
        <Typography variant="subtitle2">Rs. {productPrice(product)}</Typography>
      );
    };

    const productCard = (
      <Card
        onClick={this.handleTileClick}
        className={classnames(classes.card, {
          [classes.fillHeight]: !isHeadless()
        })}
      >
        {product.name && (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
            className={classnames({
              [classes.fillHeight]: !isHeadless()
            })}
            wrap="nowrap"
          >
            <Grid item className={classes.flashTextWrap} xs={12}>
              {(isFlashDeal(product) ||
                hasFlashDealStarted(product) ||
                !isFlashDealExpired(product)) && (
                <>
                  <Grid item className={classes.bgShape1}>
                    <TypographyPart
                      variant="smallBoldWhite"
                      className={classnames(classes.flashDeal, {
                        [classes.normalFont]: !isHorizontal,
                        [classes.horizontalFont]: isHorizontal
                      })}
                    >
                      <FlashOnIcon className={classes.flashDealIcon} />
                      Flash Deal
                    </TypographyPart>
                  </Grid>
                  <Grid item className={classes.bgShape2}>
                    <TypographyPart
                      variant="smallBoldWhite"
                      className={classnames(classes.timerText, {
                        [classes.normalFont]: !isHorizontal,
                        [classes.horizontalFont]: isHorizontal
                      })}
                    >
                      {isFlashDeal(product) && (
                        <TimerPart
                          beforeDeal={false}
                          initialTime={timeDiffBreakdown(
                            product.flash_deal.end_time
                          )}
                          isDone={() => this.refreshProduct(product)}
                        />
                      )}
                      {hasFlashDealStarted(product) && (
                        <TimerPart
                          beforeDeal={true}
                          initialTime={timeDiffBreakdown(
                            product.flash_deal.start_time
                          )}
                          isDone={() => this.refreshProduct(product)}
                        />
                      )}
                    </TypographyPart>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs className={classes.marginTop1}>
              <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="stretch"
                className={classnames({
                  [classes.fillHeight]: !isHeadless()
                })}
              >
                <Grid
                  item
                  className={classnames(classes.noDecoration, {
                    [classes.gutterBottom2]: !isHorizontal,
                    [classes.gutterBottom1]: isHorizontal
                  })}
                  component={Link}
                  to={getProductPageUrl(product)}
                  onClick={this.handleProductClick.bind(this, product)}
                  xs
                >
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item className={classes.cardMediaItem}>
                      <ImageWithLoaderPart
                        alt={product.name}
                        src={getImage(product)}
                        width="130"
                        height="130"
                        state={state}
                        loaderClass={classes.marginLeft1}
                        className={classnames(classes.centerAlignedImage, {
                          [classes.cardMediaHorizontal]: isHorizontal,
                          [classes.cardMedia]: !isHorizontal,
                          [classes.outOfStock]: product.vendor_status === 0
                        })}
                      />
                      {product.vendor_status === 0 && (
                        <OutOfStock className={classes.outOfStockSVG} />
                      )}
                    </Grid>
                    <Grid
                      className={classnames({
                        [classes.horizontalMinHeight]: isHorizontal,
                        [classes.tileMinHeight]: !isHorizontal
                      })}
                      item
                    >
                      <Typography variant="body1">
                        {productName(product.name, nameCharLimit)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Grid
                    container
                    direction="column"
                    justify="flex-end"
                    alignItems="stretch"
                  >
                    <Grid
                      item
                      className={classnames(
                        classes.gutterBottom1,
                        classes.noDecoration
                      )}
                    >
                      <Typography variant="caption">{product.unit}</Typography>
                    </Grid>
                    <Grid
                      item
                      className={classnames(
                        classes.gutterBottom1,
                        classes.noDecoration,
                        classes.priceHeight
                      )}
                    >
                      {getPrice(product)}
                    </Grid>
                    {isHorizontal && (
                      <Grid item className={classes.discountHeight} xs={12}>
                        <TypographyPart
                          variant="smallBoldWhite"
                          className={classnames({
                            [classes.dealFlash]:
                              isDeal(product) || isFlashDeal(product),
                            [classes.hiddenDeal]:
                              !isDeal(product) && !isFlashDeal(product)
                          })}
                        >
                          {isFlashDeal(product)
                            ? product.flash_deal.title
                            : product.deal_title}
                        </TypographyPart>
                      </Grid>
                    )}
                    {product.vendor_status === 0 ? (
                      <Grid item>
                        <Button
                          variant="outlined"
                          className={classes.largeButton}
                          color="primary"
                          onClick={() => remindMe(this.props, product)}
                          disabled={productStore.notifiedItems.includes(
                            product.vendor_product_id
                          )}
                        >
                          Notify Me!
                        </Button>
                      </Grid>
                    ) : (
                      <Grid item>
                        <AddToCartButtonPart
                          listName={listName}
                          query={query}
                          CTListName={CTListName}
                          product={product}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Card>
    );

    return lazyLoadComponentInViewPort(productCard, {
      once: true,
      resize: true,
      height: 200,
      style: { height: "100%" }
    });
  }
}

ProductTilePart.propTypes = {
  product: PropTypes.object.isRequired,
  isHorizontal: PropTypes.bool,
  nameCharLimit: PropTypes.func | PropTypes.number,
  listName: PropTypes.string,
  CTListName: PropTypes.string,
  query: PropTypes.string
};
ProductTilePart.defaultProps = {
  isHorizontal: false,
  nameCharLimit: selectWebOrMobile(55, 30, 100)
};

export default withRouter(withSnackbar(withCustomStyles(ProductTilePart)));
