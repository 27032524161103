import { action, autorun, computed, observable, toJS } from "mobx";
import { loadData, objToMap, saveData } from "../utils/StoreUtils";
import {
  maxPurchaseLimitReached,
  productActivePrice,
  productPrice
} from "../utils/ProductUtils";
import {
  DEFAULT_DEBOUNCE,
  PAYFAST_PAYMENT_METHOD
} from "../constatns/AppConstants";
import customerStore from "./CustomerStore";
import { postRequest } from "../utils/RestMiddlewareUtil";
import { _forEach, _has, _map, _merge } from "../utils/HelperUtils";
import {
  isSavyour,
  isEasyPaisaMiniApp,
  logInfoMessage,
  logMessage
} from "../utils/AppUtils";
import gtmService from "../services/GTMService";
import globalSettingStore from "./GlobalSettingStore";
import paymentStore from "./PaymentStore";
import uiStore from "./UIStore";
import locationStore from "./LocationStore";
import { redirectToPayfastPage } from "../utils/OrderUtils";

class OrderStore {
  @observable all = new Map();
  @observable notFound = new Map();
  @observable timeSlot = "";
  @observable state = "pending";
  @observable placeOrderState = "pending";
  @observable verifyPaymentState = "pending";
  @observable checkAvailabilityState = "pending";
  @observable comments = "";
  @observable successfulOrder = {};
  @observable successfulPayment = {};
  @observable shouldUseWallet = false;
  @observable deliveryType = "scheduled";
  @observable expressDelivery = {};
  promise;
  disposeAutorun;

  constructor() {
    this.persistData();
  }

  @computed get vendorId() {
    return customerStore.vendorId;
  }

  @computed get locationCoordinates() {
    return customerStore.customer.location_coordinates;
  }

  @computed get paymentMethod() {
    return paymentStore.selectedPaymentMethod;
  }

  @computed get selectedCard() {
    return paymentStore.selectedCard;
  }

  @action
  setDeliveryType(deliveryType) {
    this.deliveryType = deliveryType;
  }

  @action
  setComments(comments) {
    this.comments = comments;
  }

  @action
  setShouldUseWallet(shouldUseWallet) {
    if (customerStore.pendingWallet) {
      this.shouldUseWallet = true;
    } else {
      this.shouldUseWallet = shouldUseWallet;
    }
  }

  @action
  setTimeSlot(timeSlot) {
    this.timeSlot = timeSlot;
  }

  @computed get vendor() {
    return customerStore.vendor;
  }

  @computed get customer() {
    return customerStore.customer;
  }

  @computed get wallet() {
    return customerStore.wallet;
  }

  @action
  cancel() {
    this.state = "pending";
    if (this.promise) {
      this.promise.cancel();
    }
    this.cancelPromise("placeOrder");
    this.cancelPromise("checkAvailability");
  }

  @action
  cancelPromise(callee) {
    if (this[callee + "Promise"]) {
      this[callee + "Promise"].cancel();
    }
    this[callee + "State"] = "pending";
  }

  getOrderBody(cvv) {
    const order_items = _map(Array.from(this.all.values()), product => {
      return {
        product_id: product.id,
        quantity: product.order_quantity
      };
    });
    const time_slot_id = this.timeSlot.split("||")[0] || "";
    const delivery_date = this.timeSlot.split("||")[1] || "";
    const address_id = locationStore.currentAddress.id;
    const body = {
      delivery_type: this.deliveryType,
      address_id,
      order_items,
      should_use_wallet: (this.canUseWallet && this.shouldUseWallet) || false,
      payment_mode: this.paymentMethod,
      source: isSavyour() ? "web_savyour" : "web",
      ...(isSavyour() && { channel: "savyour" })
    };
    if (this.deliveryType === "scheduled") {
      _merge(body, {
        time_slot_id,
        delivery_date
      });
    }
    if (this.paymentMethod === "card") {
      _merge(body, {
        card_id: this.selectedCard.id,
        cvv
      });
    }
    if (this.paymentMethod === PAYFAST_PAYMENT_METHOD) {
      _merge(body, {
        payment_mode: "card",
        payment_type: PAYFAST_PAYMENT_METHOD,
        card: null,
        card_id: null
      });
    }
    if (this.comments) {
      _merge(body, {
        comments: this.comments
      });
    }
    if (!time_slot_id || !delivery_date) {
      logMessage("Invalid timeslot id", body);
    }
    return body;
  }

  isSuccessfulOrderValid() {
    return (
      _has(this.successfulOrder, "delivery_start_time.date") &&
      _has(this.successfulOrder, "delivery_time.date")
    );
  }

  @action
  placeOrder(cvv, success = () => {}, fail = () => {}, error = () => {}) {
    this.cancelPromise("placeOrder");
    this.placeOrderState = "fetching";
    const body = this.getOrderBody(cvv);
    this.placeOrderPromise = postRequest(
      this,
      "v15/order/create",
      body,
      data => {
        if (this.shouldUseWallet) {
          customerStore.fetchCustomerWithWallet();
          this.setShouldUseWallet(false);
        }
        this.successfulOrder = data;
        if (!this.isSuccessfulOrderValid()) {
          logMessage("order success response not valid", {
            data
          });
        }
        logInfoMessage("place order response from backend", {
          data,
          body,
          queryParams: window.location.search,
          url: "v15/order/create"
        });
        success(data);
        this.placeOrderState = "done";
        if (!data.paymentResponse) {
          this.all.clear();
          this.notFound.clear();
        } else {
          redirectToPayfastPage(data.paymentResponse.REDIRECTION_HTML);
          this.all.clear();
          this.notFound.clear();
        }
      },
      data => {
        fail(data);
        logMessage("Make order failed", {
          customer: this.customer,
          body,
          failResponse: data
        });
        this.placeOrderState = "fail";
      },
      e => {
        error(e);
        logMessage("Make order error", {
          customer: this.customer,
          body,
          errorResponse: e
        });
        this.placeOrderState = "error";
      }
    );
  }

  @action
  easyPaisaPlaceOrder(success = () => {}, fail = () => {}, error = () => {}) {
    this.cancelPromise("placeOrder");
    this.placeOrderState = "fetching";

    const body = this.getOrderBody();
    this.placeOrderPromise = postRequest(
      this,
      "v1/easypaisa/order/create",
      body,
      data => {
        this.successfulOrder = data;
        if (!this.isSuccessfulOrderValid()) {
          logMessage("order success response not valid", {
            data
          });
        }
        logInfoMessage("Order placed successfully", {
          environment: isEasyPaisaMiniApp() ? "easypaisa_miniapp" : "web",
          data,
          body,
          url: "v1/easypaisa/order/create"
        });
        success(data);
        this.placeOrderState = "done";
      },
      data => {
        fail(data);
        logMessage("Make order failed", {
          customer: this.customer,
          body,
          url: "v1/easypaisa/order/create",
          failResponse: data
        });
        this.placeOrderState = "fail";
      },
      e => {
        error(e);
        logMessage("Make order error", {
          customer: this.customer,
          body,
          url: "v1/easypaisa/order/create",
          errorResponse: e
        });
        this.placeOrderState = "error";
      }
    );
  }

  @action
  handleEasyPaisaOrderCleanUp = isSuccess => {
    if (isSuccess) {
      this.all.clear();
      this.notFound.clear();

      if (this.shouldUseWallet) {
        customerStore.fetchCustomerWithWallet();
        this.setShouldUseWallet(false);
      }
    }
  };

  @action
  verifyPayment(url) {
    this.cancelPromise("verifyPayment");
    this.verifyPaymentState = "fetching";
    window.location.href = url;
  }

  @action
  checkAvailability(success = () => {}) {
    let defaultLocationCoordinates = `${uiStore.defaultDeliveryLocation.lat},${uiStore.defaultDeliveryLocation.lng}`;
    const orderItems = [];
    this.all.forEach(obj => {
      const temp_obj = { product_id: obj.id, quantity: obj.order_quantity };
      orderItems.push(temp_obj);
    });
    this.notFound.forEach(obj => {
      const temp_obj = { product_id: obj.id, quantity: obj.order_quantity };
      orderItems.push(temp_obj);
    });
    if (orderItems.length < 1) {
      return;
    }
    if (
      window.location.pathname &&
      !window.location.pathname.startsWith("/cart") &&
      !window.location.pathname.startsWith("/checkout")
    ) {
      return;
    }
    this.cancelPromise("checkAvailability");
    this.checkAvailabilityState = "fetching";
    this.checkAvailabilityPromise = postRequest(
      this,
      "v3/products/fetch/by/vendor-id/product-ids",
      {
        order_items: orderItems,
        vendor_id: this.vendorId,
        location_coordinates: this.locationCoordinates
          ? this.locationCoordinates
          : defaultLocationCoordinates
      },
      data => {
        const notFoundArr = data["not-found"];
        const foundArr = data["found"];

        _forEach(notFoundArr, id => {
          let notFound = this.all.get(id);
          if (!notFound) {
            notFound = this.notFound.get(id);
          }
          this.notFound.set(notFound.id, notFound);
          this.all.delete(id);
        });

        _forEach(foundArr, serverProduct => {
          let localProduct = this.all.get(serverProduct.id);
          if (!localProduct) {
            localProduct = this.notFound.get(serverProduct.id);
          }
          this.all.set(localProduct.id, _merge(localProduct, serverProduct));
          this.notFound.delete(localProduct.id);
        });

        if (notFoundArr.length < 1) {
          this.notFound = new Map();
        }

        if (foundArr.length < 1) {
          this.all = new Map();
        }
        this.expressDelivery = data.express;
        this.checkAvailabilityState = "done";
        success();
        gtmService.checkoutCartStep(orderStore.getAllArray(), orderStore);
      },
      () => {
        this.checkAvailabilityState = "fail";
      },
      () => {
        this.checkAvailabilityState = "error";
      }
    );
  }

  getAllArray() {
    return _map(Array.from(this.all.values()), product => toJS(product));
  }

  cancelAndDisposeAutorun() {
    this.cancel();
    this.disposeAutorun();
  }

  persistData() {
    const key = "orderStore";
    loadData(this, key, data => {
      this.all = objToMap(data.all);
      this.notFound = objToMap(data.notFound);
      this.comments = data.comments;
      this.setShouldUseWallet(data.shouldUseWallet);
      this.checkAvailability();
    });
    this.disposeAutorun = autorun(
      () => {
        saveData(this, key, {
          all: toJS(this.all),
          notFound: toJS(this.notFound),
          comments: toJS(this.comments),
          shouldUseWallet: toJS(this.shouldUseWallet)
        });
      },
      { delay: DEFAULT_DEBOUNCE }
    );
  }

  @computed
  get subTotal() {
    let sum = 0;
    // eslint-disable-next-line no-unused-vars
    for (const val of this.all.values()) {
      sum += productActivePrice(val) * val.order_quantity;
    }
    return sum;
  }

  @computed
  get subTotalSavings() {
    let sum = 0;
    // eslint-disable-next-line no-unused-vars
    for (const val of this.all.values()) {
      sum += (productPrice(val) - productActivePrice(val)) * val.order_quantity;
    }
    return sum;
  }

  @action
  addOne(product, listName = "", CTListName = "", query = "") {
    if (!product) return;
    if (maxPurchaseLimitReached(product)) return;
    const id = product.id;
    if (this.all.has(id)) {
      this.all.get(id).order_quantity++;
    } else {
      product.order_quantity = 1;
      this.all.set(id, product);
    }
    product.quantity = 1;
    product = toJS(product);
    gtmService.addToCart(product, listName, CTListName, query, this.customer);
  }

  @action
  remove(product, listName, CTListName = "") {
    if (!product) {
      return;
    }

    let analyticsProduct = this.all.get(product.id);
    analyticsProduct.quantity = analyticsProduct.order_quantity;
    analyticsProduct = toJS(analyticsProduct);

    gtmService.removeFromCart(analyticsProduct, listName, CTListName);
    this.all.delete(product.id);
  }

  @action
  emptyCart(listName = "", CTListName = "") {
    if (this.all.size === 0) {
      return;
    }
    this.all.forEach(product => {
      let analyticsProduct = this.all.get(product.id);
      analyticsProduct.quantity = analyticsProduct.order_quantity;
      analyticsProduct = toJS(analyticsProduct);
      gtmService.removeFromCart(analyticsProduct, listName, CTListName);
    });

    this.all.clear();
  }

  @action
  removeNotFound(product) {
    if (!product) {
      return;
    }
    this.notFound.delete(product.id);
  }

  @action
  removeOne(product, listName, CTListName) {
    if (!product) {
      return;
    }
    const id = product.id;
    const storeProduct = this.all.get(id);
    if (storeProduct && storeProduct.order_quantity === 1) {
      storeProduct.order_quantity = 0;
      this.all.delete(id);
    } else {
      storeProduct.order_quantity--;
      this.all.set(id, storeProduct);
    }
    product.quantity = 1;
    product = toJS(product);
    gtmService.removeFromCart(product, listName, CTListName);
  }

  getQuantity = product => {
    if (!product) {
      return 0;
    }
    const id = product.id;
    if (this.all.has(id)) {
      return this.all.get(id).order_quantity;
    }
    return 0;
  };

  @computed get totalBillWithDelivery() {
    return this.isDeliveryFree
      ? this.subTotal
      : this.deliveryType === "scheduled"
      ? this.subTotal + this.vendor.delivery_charges
      : this.subTotal + +this.vendor.express_delivery_charges;
  }

  @computed get subTotalWithoutDelivery() {
    if (
      customerStore.isLoggedIn &&
      (this.shouldUseWallet || customerStore.pendingWallet)
    ) {
      return this.subTotal - this.wallet.amount > 0
        ? this.subTotal - this.wallet.amount
        : 0;
    }
    return this.subTotal;
  }

  @computed get totalBill() {
    if (
      customerStore.isLoggedIn &&
      (this.shouldUseWallet || customerStore.pendingWallet)
    ) {
      return this.totalBillWithDelivery - this.wallet.amount > 0
        ? this.totalBillWithDelivery - this.wallet.amount
        : 0;
    }
    return this.totalBillWithDelivery;
  }

  @computed get canUseWallet() {
    return (
      customerStore.isLoggedIn &&
      (this.subTotal >= globalSettingStore.settings.wallet_order_limit ||
        customerStore.pendingWallet ||
        this.customer.is_member)
    );
  }

  @computed get deliveryCharges() {
    if (this.deliveryType === "scheduled") {
      return this.isDeliveryFree ? 0 : this.vendor.delivery_charges;
    } else {
      return this.isDeliveryFree ? 0 : this.vendor.express_delivery_charges;
    }
  }

  @computed get isDeliveryFree() {
    if (this.deliveryType === "scheduled") {
      if (this.freeScheduledDeliveryLeft) {
        return true;
      }
      return this.subTotal >= this.customer.delivery_free_after;
    } else {
      if (this.freeExpressDeliveryLeft) {
        return true;
      }
      if (!this.customer.express_delivery_free_after) {
        return false;
      }
      return this.subTotal >= this.customer.express_delivery_free_after;
    }
  }

  @computed get isScheduledFree() {
    return this.subTotal >= this.customer.delivery_free_after;
  }

  @computed get isExpressFree() {
    if (this.customer.express_delivery_free_after) {
      return this.subTotal >= this.customer.express_delivery_free_after;
    } else {
      return false;
    }
  }

  @computed get isExpressEligible() {
    return this.expressDelivery && this.expressDelivery.status === "eligible";
  }

  @computed get isExpressIneligible() {
    return this.expressDelivery && this.expressDelivery.status === "ineligible";
  }

  @computed get shopMoreForFreeDelivery() {
    if (this.deliveryType === "scheduled") {
      return this.customer.delivery_free_after - this.subTotal;
    } else {
      return this.expressDeliveryMinAmount - this.subTotal;
    }
  }

  @computed get expressDeliveryMinAmount() {
    return this.customer.express_delivery_free_after
      ? this.customer.express_delivery_free_after
      : this.vendor.express_delivery_free_after;
  }

  @computed get freeExpressDeliveryLeft() {
    return (
      this.customer.is_member &&
      this.expressDelivery &&
      this.expressDelivery.remaining_free_express_deliveries > 0
    );
  }

  @computed get freeScheduledDeliveryLeft() {
    return this.customer.free_deliveries > 0;
  }
}

const orderStore = new OrderStore();
export default orderStore;
