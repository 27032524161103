import React from "react";
import withCustomStyles from "./BreadcrumbsPart.style";
import BreadcrumbsListPart from "./BreadcrumbsListPart";
import Paper from "@material-ui/core/Paper";
import { inject, observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import BreadcrumbsListSchemaPart from "./BreadcrumbsListSchemaPart";
import Skeleton from "react-loading-skeleton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

@inject(["categoriesStore"])
@observer
class BreadcrumbsPart extends React.Component {
  render() {
    const {
      props: {
        categoriesStore,
        cid,
        isSiteMap,
        isProductDetailPage,
        classes,
        productName
      }
    } = this;
    const categoryName = categoriesStore.find(cid).name;

    const LoadingPart = () => {
      return (
        <Paper className={classes.breadCrumbs}>
          <Breadcrumbs separator="›" arial-label="Breadcrumb">
            <Skeleton height={18} width={40} />
            <Skeleton height={18} width={100} />
            <Skeleton height={18} width={70} />
          </Breadcrumbs>
          {!isProductDetailPage && (
            <Typography variant={"h1"} className={classes.categoryName}>
              <Skeleton height={25} width={150} />
            </Typography>
          )}
        </Paper>
      );
    };

    return (categoriesStore.all.length === 0 || !categoryName) &&
      categoriesStore.state === "fetching" ? (
      <LoadingPart />
    ) : (
      <Paper className={classes.breadCrumbs}>
        <BreadcrumbsListPart
          productName={productName}
          isSiteMap={isSiteMap}
          categories={categoriesStore.parents(cid)}
        />
        {!isProductDetailPage && (
          <Typography variant={"h1"} className={classes.categoryName}>
            {categoryName}
          </Typography>
        )}
        <BreadcrumbsListSchemaPart categories={categoriesStore.parents(cid)} />
      </Paper>
    );
  }
}

BreadcrumbsPart.propTypes = {
  cid: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withRouter(withCustomStyles(BreadcrumbsPart));
