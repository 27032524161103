// 3rd party
import React, { Component } from "react";
import { Route, withRouter } from "react-router";
import _startsWith from "lodash/startsWith";
import { inject, observer } from "mobx-react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Grid from "@material-ui/core/Grid";
import NoSsr from "@material-ui/core/NoSsr";
import classnames from "classnames";
import "lazysizes";

// lib
import HeaderPart from "./parts/HeaderPart";
import { mainThemeObject } from "./theme/MainTheme";
import { isStandalonePage } from "./utils/UrlUtils";
import LoadMorePart from "./parts/LoadMorePart";
import RouteWrapper from "./RouteWrapper";
import withCustomStyles from "./MainWrapper.style";
import { isHeadless, isLightHouse } from "./utils/AppUtils";
import { when } from "mobx";

@inject(["uiStore"])
@inject(["customerStore"])
@inject(["categoriesStore"])
@observer
class MainWrapper extends Component {
  vendorId = null;
  state = {
    showScrollTopBtn: false,
    scrollBtnPostion: 30,
    vendorDetailRequest: false
  };

  fetchVendorInfo = new Promise(resolve => {
    const {
      props: { customerStore }
    } = this;
    if (customerStore.isLoggedIn) {
      customerStore.fetchCustomerInfo(() => {
        resolve("done");
      });
    } else {
      customerStore.fetchVendor(undefined, () => {
        resolve("done");
      });
    }
  });

  fetchCustomerAndVendor = () => {
    const {
      props: { customerStore, categoriesStore }
    } = this;

    this.setState({
      vendorDetailRequest: true
    });

    this.fetchVendorInfo.then(() => {
      this.vendorId = customerStore.vendorId;
      categoriesStore.fetch();
      this.setState({
        vendorDetailRequest: false
      });
    });
  };

  toggleScrollBtn = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      this.setState({
        showScrollTopBtn: true,
        scrollBtnPostion: 30
      });
    } else if (scrolled <= 300) {
      this.setState({
        showScrollTopBtn: false
      });
    }

    if (
      window.innerHeight + window.scrollY > document.body.clientHeight - 50 &&
      !this.isGreaterThanMd()
    ) {
      this.setState({
        scrollBtnPostion: 70
      });
    }
  };

  componentDidMount = () => {
    const { customerStore } = this.props;

    if (isHeadless() || isLightHouse()) {
      this.fetchCustomerAndVendor();
    }

    this.disposeWhen = when(
      () => customerStore.isTokenLoaded && customerStore.isCustomerLoaded,
      () => {
        if (!this.state.vendorDetailRequest) {
          this.fetchCustomerAndVendor();
        }
      }
    );

    window.addEventListener("scroll", this.toggleScrollBtn);
    if (window && window.innerWidth) {
      this.props.uiStore.setScreenWidth(window.innerWidth);
    }
  };

  componentDidUpdate() {
    const {
      props: { uiStore, customerStore },
      vendorId,
      fetchCustomerAndVendor
    } = this;

    if (
      vendorId !== customerStore.vendorId &&
      !this.state.vendorDetailRequest
    ) {
      fetchCustomerAndVendor();
    }

    if (window && window.innerWidth) {
      uiStore.setScreenWidth(window.innerWidth);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleScrollBtn);
    this.props.categoriesStore.cancel();
  }

  isGreaterThanMd = () => {
    return (
      this.props.uiStore.screenWidth >= mainThemeObject.breakpoints.values.md
    );
  };

  handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  render() {
    const {
      state: { showScrollTopBtn, scrollBtnPostion },
      props: {
        classes,
        uiStore,
        customerStore,
        categoriesStore,
        location: { pathname }
      },
      handleScrollTop
    } = this;

    const location =
      uiStore &&
      uiStore.defaultDeliveryLocation &&
      uiStore.defaultDeliveryLocation.location;

    let content = (
      <React.Fragment>
        <main
          className={classnames(
            {
              [classes.mdWidth]: this.isGreaterThanMd()
            },
            {
              [classes.upLift]: isStandalonePage(pathname)
            }
          )}
        >
          <HeaderPart />
          {(_startsWith(pathname, "/redirect") ||
            (location && categoriesStore.all.length) ||
            pathname === "/change-location") &&
          uiStore.dataLoaded ? (
            <>
              <RouteWrapper />
            </>
          ) : (
            location && <LoadMorePart />
          )}
        </main>
      </React.Fragment>
    );

    if (pathname === "/apple-app-site-association") {
      const reload = () => window.location.reload();
      content = <Route path="/apple-app-site-association" onEnter={reload} />;
    }

    const forGreaterThanMd = (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>{content}</Grid>
      </Grid>
    );

    return (
      <NoSsr>
        <div className={classes.app}>
          <CssBaseline />
          {customerStore.logoutState === "fetching" && <LoadMorePart />}
          {customerStore.logoutState !== "fetching" &&
            this.isGreaterThanMd() &&
            forGreaterThanMd}
          {customerStore.logoutState !== "fetching" &&
            !this.isGreaterThanMd() &&
            content}
          {showScrollTopBtn && uiStore.isScrollTopBtn && (
            <Button
              className={classes.scrollBtn}
              color="primary"
              variant="contained"
              onClick={handleScrollTop}
              style={{ bottom: scrollBtnPostion }}
            >
              <ArrowUpwardIcon />
            </Button>
          )}
        </div>
      </NoSsr>
    );
  }
}

export default withRouter(withCustomStyles(MainWrapper));
