import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  salientFeaturesRow: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  iconItem: {
    paddingRight: theme.spacing(2)
  }
}));

export default withCustomStyles;
