import React from "react";
import withCustomStyles from "./LoadMorePart.style";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import classnames from "classnames";
import ImageWithLoaderPart from "./ImageWithLoaderPart";
import { Breadcrumbs, CardContent } from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import loadingCarrot from "../assets/images/loading_carrot.png";

const LoadMorePart = props => {
  return (
    <>
      {window.location.href.includes("/products/") ? (
        <div>
          <Paper className={props.classes.breadCrumbs}>
            <Breadcrumbs separator="›" arial-label="Breadcrumb">
              <Skeleton height={18} width={40} />
              <Skeleton height={18} width={100} />
              <Skeleton height={18} width={120} />
            </Breadcrumbs>
          </Paper>
          <Paper
            className={classnames(props.classes.root, props.classes.marginTop1)}
          >
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
            >
              <Grid item xs>
                <div
                  className={classnames(
                    props.classes.root,
                    props.classes.marginBottom2
                  )}
                >
                  <ImageWithLoaderPart
                    src={loadingCarrot}
                    width="374"
                    height="374"
                    alt="Loading"
                    className={classnames(
                      props.classes.cardMedia,
                      props.classes.centerAlignedImage
                    )}
                  />
                </div>
              </Grid>
              <CardContent className={props.classes.padding1}>
                <Skeleton height={30} width={250} />
                <Skeleton height={24} width={120} style={{ marginTop: 40 }} />
              </CardContent>
              <Skeleton height={42} width="100%" />
            </Grid>
          </Paper>
        </div>
      ) : (
        <Paper className={classnames(props.classes.loadMore, props.className)}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

LoadMorePart.propTypes = {
  className: PropTypes.string
};
export default withCustomStyles(LoadMorePart);
