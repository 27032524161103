import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import withCustomStyles from "./CategoriesPart.style";
import Grid from "@material-ui/core/Grid";
import { withRouter } from "react-router";
import CategoriesGridPart from "./CategoriesGridPart";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import Paper from "@material-ui/core/Paper";
import loadingCarrot from "../assets/images/loading_carrot.png";
import Skeleton from "react-loading-skeleton";
import ImageWithLoaderPart from "./ImageWithLoaderPart";
import CreateListPart from "./CreateListPart";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";

@inject(["categoriesStore"])
@observer
class CategoriesPart extends Component {
  render() {
    const { classes, categoriesStore, isSiteMap } = this.props;

    const LoadingPart = () => (
      <CreateListPart count={5}>
        <Grid item xs={12} sm={12} className={classes.productItem}>
          <Card className={classnames(classes.categoryCard)}>
            <Grid
              container
              direction="row"
              justify="space-between"
              className={classes.mainCategoryRow}
              alignItems="center"
            >
              <Grid item xs={12} className={classes.mainCategoryText}>
                <CardContent>
                  <Typography variant="h6">
                    <Skeleton width={120} height={25} />
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
            <Collapse in timeout="auto" unmountOnExit>
              <Paper>
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                >
                  <CreateListPart count={3}>
                    <Grid item className={classes.noDecoration} sm={3} xs={4}>
                      <Card className={classes.subCategoryCard}>
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          alignItems="stretch"
                        >
                          <Grid item xs sm={12}>
                            <ImageWithLoaderPart
                              width="80"
                              height="60"
                              src={loadingCarrot}
                              alt="Loading"
                              loaderClass={classes.marginLeft1}
                              className={classes.subCategoriesCardMedia}
                            />
                          </Grid>
                          <Grid item xs sm={8}>
                            <CardContent>
                              <Skeleton height={20} width={100} />
                            </CardContent>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </CreateListPart>
                </Grid>
              </Paper>
            </Collapse>
          </Card>
        </Grid>
      </CreateListPart>
    );

    return (
      <div className={classes.root}>
        {categoriesStore.all.length === 0 &&
          categoriesStore.state === "fetching" && <LoadingPart />}
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <CategoriesGridPart
            isSiteMap={isSiteMap}
            categories={categoriesStore.all}
          />
        </Grid>
      </div>
    );
  }
}

export default withRouter(withCustomStyles(CategoriesPart));
