import React, { Component, lazy, Suspense } from "react";
import { inject, observer } from "mobx-react";
import withCustomStyles from "./ProductDetailPage.style";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TypographyPart from "../parts/TypographyPart";
import classnames from "classnames";
import CardContent from "@material-ui/core/CardContent";
import AddToCartButtonPart from "../parts/AddToCartButtonPart";
import { timeDiffBreakdown } from "../utils/DateUtils";
import BreadcrumbsPart from "../parts/BreadcrumbsPart";
import {
  dealPrice,
  firstBrandName,
  getProductId,
  isDeal,
  isFlashDeal,
  productAvailability,
  productIsAvailable,
  productPrice,
  remindMe,
  hasFlashDealStarted,
  isFlashDealExpired,
  trimStr
} from "../utils/ProductUtils";
import {
  getProductAllFullImages,
  getProductPageAbsUrl,
  productFullImage,
  getImage,
  getSearchPageUrl,
  getProductPageUrl,
  getQueryParam,
  getProductIdFromUrl
} from "../utils/UrlUtils";
import Button from "@material-ui/core/Button";
import { withSnackbar } from "notistack";
import ImageWithLoaderPart from "../parts/ImageWithLoaderPart";
import {
  productDetailDesc,
  productDetailDescStructured,
  productDetailTitle
} from "../utils/SEOUtils";
import SEOInfoPart from "../parts/SEOInfoPart";
import { Helmet } from "react-helmet";
import { getCategoryName, getCategoryNamesTree } from "../utils/CategoryUtils";
import NotFoundPart from "../parts/NotFoundPart";
import BrandNamesTextLinksPart from "../parts/BrandNamesTextLinksPart";
import TimerPart from "../parts/TimerPart";
import Slider from "react-slick";
import componentLoader from "../utils/ComponentLoader";
import { ReactComponent as OutOfStock } from "../assets/images/out_of_stock.svg";
import { lazyLoadComponentInViewPort } from "../utils/lazyLoadComponent";
import loadingCarrot from "../assets/images/loading_carrot.png";
import Skeleton from "react-loading-skeleton";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import SimpleDialogPart from "../parts/SimpleDialogPart";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const ProductsHorizontalSectionPart = lazy(() =>
  componentLoader(import("../parts/ProductsHorizontalSectionPart"))
);

@inject(["uiStore"])
@inject(["productStore"])
@inject(["customerStore"])
@inject(["orderStore"])
@inject(["topSellingStore"])
@inject(["globalSettingStore"])
@inject(["similarProductsStore"])
@inject(["productBasedRecommendationsStore"])
@observer
class ProductDetailPage extends Component {
  state = {
    outOfStockPopup: false
  };

  componentDidMount() {
    const {
      location: { search, pathname },
      customerStore,
      productStore,
      history
    } = this.props;

    if (
      !customerStore.isLoggedIn &&
      getQueryParam(search, "out-of-stock-reminder")
    ) {
      productStore.toggleCurrentlyNotified();
      history.push("/login?redirect=" + pathname);
    } else {
      this.fetchAll();
    }
  }

  getProduct = product => {
    const { orderStore } = this.props;
    const orderStoreProduct = orderStore.all.get(product.id);
    if (
      orderStoreProduct &&
      orderStoreProduct.id &&
      product.order_quantity !== orderStoreProduct.order_quantity
    ) {
      return { ...product, order_quantity: orderStoreProduct.order_quantity };
    }
    return { ...product };
  };

  fetchAll() {
    const {
      productStore,
      customerStore,
      globalSettingStore,
      uiStore,
      orderStore,
      location: { pathname },
      location: { search }
    } = this.props;
    let prid = getProductIdFromUrl(pathname);

    productStore.fetch(prid, data => {
      if (
        customerStore.isLoggedIn &&
        (getQueryParam(search, "out-of-stock-reminder") ||
          productStore.currentlyNotified)
      ) {
        productStore.toggleCurrentlyNotified();
        productStore.notificationClick(data.vendor_product_id);
      }
      if (
        uiStore.locationChanged &&
        (!productIsAvailable(productStore.item) ||
          !productStore.item.is_current_vendor)
      ) {
        uiStore.setDefaultDeliveryLocation(uiStore.defaultDeliveryLocation);
        this.setState({
          outOfStockPopup: true
        });
      }

      if (
        uiStore.showLocPopup &&
        productStore.isProductDetailClicked &&
        productIsAvailable(productStore.item) &&
        productStore.item.is_current_vendor
      ) {
        uiStore.setShowLocPopup(false);
        orderStore.addOne(
          this.getProduct(productStore.item),
          "Detail Page",
          "Product Detail Page",
          undefined
        );
      }
    });
    globalSettingStore.registerDevice();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname }
    } = this.props;
    let prid = getProductIdFromUrl(pathname);
    let oldPrid = getProductIdFromUrl(prevProps.location.pathname);

    if (prid !== oldPrid) {
      this.cancelAll();
      this.fetchAll();
    }
  }

  refreshProduct = product => {
    const {
      props: { productStore }
    } = this;
    if (productStore.item.id === product.id) {
      productStore.item.flash_deal.status =
        productStore.item.flash_deal.status === 1
          ? 2
          : productStore.item.flash_deal.status === 2
          ? 0
          : productStore.item.flash_deal.status;
    }
  };

  handleLoadMore = () => {
    const {
      location: { pathname },
      productBasedRecommendationsStore
    } = this.props;
    let prid = getProductIdFromUrl(pathname);
    productBasedRecommendationsStore.loadMore(prid);
  };

  cancelAll() {
    const { productStore } = this.props;
    productStore.cancel();
  }

  closeOutofStockPopup = () => {
    this.setState({
      outOfStockPopup: false
    });
  };

  componentWillUnmount() {
    this.cancelAll();
  }

  render() {
    const {
      props: {
        classes,
        productStore,
        match,
        topSellingStore,
        similarProductsStore,
        productBasedRecommendationsStore,
        productStore: { item },
        location: { pathname }
      },
      state: { outOfStockPopup },
      closeOutofStockPopup,
      handleLoadMore
    } = this;
    let prid = getProductIdFromUrl(pathname);
    if (!item) {
      return <div>{match.params.prn}</div>;
    }

    const settings = {
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToScroll: true,
      initialSlide: 0,
      centerPadding: 60
    };

    const categoryName = getCategoryName(item.category_id);
    const seoDescription = productDetailDesc(
      item.name,
      item.unit,
      categoryName,
      item.desc,
      item.desc_article
    );
    const seoDescriptionStructured = productDetailDescStructured(
      item.name,
      item.unit,
      categoryName,
      item.desc,
      item.desc_article
    );
    const seoTitle = productDetailTitle(item.name, categoryName);

    const getPrice = product => {
      if (
        isFlashDeal(product) ||
        isDeal(product) ||
        !isFlashDealExpired(product)
      ) {
        return (
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="baseline"
          >
            <Grid item>
              <Typography variant="body1">Rs. {dealPrice(product)}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.nonDealPrice}>
                Rs. {productPrice(product)}
              </Typography>
            </Grid>
            <Grid item>{getDeal(item)}</Grid>
          </Grid>
        );
      }
      return (
        <Typography variant="body1">Rs. {productPrice(product)}</Typography>
      );
    };

    const getDeal = product => {
      if (
        isFlashDeal(product) ||
        isDeal(product) ||
        !isFlashDealExpired(product)
      ) {
        return (
          <Grid item className={classes.textLineHeight} xs={12}>
            <TypographyPart
              variant="smallBoldWhite"
              className={classes.dealFlash}
            >
              {isFlashDeal(product)
                ? product.flash_deal.title
                : product.deal_title}
            </TypographyPart>
          </Grid>
        );
      }
    };

    const bottomHorizontalSection = (
      <>
        <Suspense fallback={<div />}>
          <ProductsHorizontalSectionPart
            name="You May Also Like"
            onScroll={handleLoadMore}
            fetch={() => productBasedRecommendationsStore.fetch(prid)}
            cancel={productBasedRecommendationsStore.cancel}
            state={productBasedRecommendationsStore.fetchState}
            listName="Home Page - More To Love"
            CTListName="Horizontal Section - More To Love"
            products={productBasedRecommendationsStore.all}
          />
        </Suspense>
        <Suspense fallback={<div />}>
          <ProductsHorizontalSectionPart
            name="Most popular"
            state={topSellingStore.fetchState}
            fetch={topSellingStore.fetch}
            cancel={topSellingStore.cancel}
            listName="Detail Page - Most popular"
            CTListName="Horizontal Section - Top Selling"
            products={topSellingStore.all}
          />
        </Suspense>
      </>
    );

    const LoadingPart = () => (
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item xs>
          <div className={classnames(classes.root, classes.marginBottom2)}>
            <ImageWithLoaderPart
              src={loadingCarrot}
              width="374"
              height="374"
              alt="Loading"
              className={classnames(
                classes.cardMedia,
                classes.centerAlignedImage
              )}
            />
          </div>
        </Grid>
        <CardContent className={classes.padding1}>
          <Skeleton height={30} width={250} />
          <Skeleton height={24} width={120} style={{ marginTop: 40 }} />
        </CardContent>
        <Skeleton height={42} width="100%" />
      </Grid>
    );

    return (
      <>
        {productStore.isFailed && <NotFoundPart />}
        {!productStore.isFailed && (
          <div>
            <Helmet>
              <script type="application/ld+json">
                {`{
                "@context": "http://schema.org",
                "@type": "Product",
                "productID": "${getProductId(item)}",
                "mpn": "${getProductId(item)}",
                "name": "${item.name}",
                "description": "${seoDescriptionStructured}",
                "url": "${getProductPageAbsUrl(item)}",
                "image": "${productFullImage(item)}",
                "category": "${getCategoryNamesTree(item.category_id)}",
                "brand": "${firstBrandName(item)}",
                "sku": "${item.barcode}",
                "offers": [
                  {
                    "@type": "Offer",
                    "url": "${getProductPageAbsUrl(item)}",
                    "price": "${productPrice(item) || 0}",
                    "priceCurrency": "PKR",
                    "itemCondition": "http://schema.org/NewCondition",
                    "availability": "${productAvailability(item)["json"]}",
                    "eligibleQuantity": "${item.unit}",
                    "seller": {
                      "@type": "Organization",
                      "name": "GrocerApp"
                    }
                  }
                ]
              }`}
              </script>
              <script type="application/ld+json">
                {`
              {  
                "@context": "http://www.schema.org",
                "@type": "ImageObject",
                "contentUrl": "${productFullImage(item)}",
                "description": "${seoDescriptionStructured}",
                "name": "${item.name}"
              }
            `}
              </script>
              <link rel="canonical" href={getProductPageAbsUrl(item)} />
            </Helmet>
            <BreadcrumbsPart
              isProductDetailPage
              productName={item.name}
              cid={item.category_id}
            />
            <Paper className={classnames(classes.root, classes.marginTop1)}>
              {(!item.name || productStore.state === "fetching") && (
                <LoadingPart />
              )}
              {item.name && productStore.state !== "fetching" && (
                <div>
                  <SEOInfoPart
                    title={seoTitle}
                    description={seoDescription}
                    image={productFullImage(item)}
                    imageAlt={item.name}
                    url={getProductPageAbsUrl(item)}
                  />
                  {(isFlashDeal(item) || hasFlashDealStarted(item)) && (
                    <Grid item className={classes.flashTextWrap}>
                      <Grid item className={classes.bgShape1}>
                        <TypographyPart
                          variant="smallBoldWhite"
                          className={classes.flashDeal}
                        >
                          <FlashOnIcon className={classes.flashDealIcon} />
                          Flash Deal
                        </TypographyPart>
                      </Grid>
                      <Grid item className={classes.bgShape2}>
                        <TypographyPart
                          variant="smallBoldWhite"
                          className={classes.timerText}
                        >
                          {isFlashDeal(item) && (
                            <TimerPart
                              beforeDeal={false}
                              initialTime={timeDiffBreakdown(
                                item.flash_deal.end_time
                              )}
                              isDone={() => this.refreshProduct(item)}
                            />
                          )}
                          {hasFlashDealStarted(item) && (
                            <TimerPart
                              beforeDeal={true}
                              initialTime={timeDiffBreakdown(
                                item.flash_deal.start_time
                              )}
                              isDone={() => this.refreshProduct(item)}
                            />
                          )}
                        </TypographyPart>
                      </Grid>
                    </Grid>
                  )}
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                  >
                    <Grid item xs>
                      <div
                        className={classnames(
                          classes.root,
                          classes.marginBottom2
                        )}
                      >
                        {productIsAvailable(item) ? (
                          <Slider {...settings}>
                            {getProductAllFullImages(item).map(
                              (image, index) => (
                                <ImageWithLoaderPart
                                  key={index}
                                  src={image}
                                  width="374"
                                  height="374"
                                  alt={item.name}
                                  className={classnames(
                                    classes.cardMedia,
                                    classes.centerAlignedImage
                                  )}
                                />
                              )
                            )}
                          </Slider>
                        ) : (
                          <Grid item className={classes.cardMediaItem}>
                            <ImageWithLoaderPart
                              alt={item.name}
                              src={getImage(item)}
                              width="374"
                              height="374"
                              loaderClass={classes.marginLeft1}
                              className={classnames(
                                classes.cardMedia,
                                classes.centerAlignedImage,
                                classes.outOfStock
                              )}
                            />
                            <OutOfStock className={classes.outOfStockSVG} />
                          </Grid>
                        )}
                      </div>
                    </Grid>
                    <CardContent className={classes.cardContent}>
                      <Grid className={classes.marginTop2} item xs>
                        <Typography
                          variant="h1"
                          className={classes.productName}
                        >
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2" className={classes.unit}>
                          Available In: {item.unit}
                        </Typography>
                        {item.brands && item.brands.length > 0 && (
                          <BrandNamesTextLinksPart brands={item.brands} />
                        )}
                        <div className={classes.unit}>{getPrice(item)}</div>
                      </Grid>
                    </CardContent>
                    <Grid item xs>
                      {productIsAvailable(item) && (
                        <AddToCartButtonPart
                          className={classes.addToCartButton}
                          listName="Detail Page"
                          CTListName="Product Detail Page"
                          product={item}
                        />
                      )}
                      {!productIsAvailable(item) && (
                        <>
                          <Button
                            variant="outlined"
                            className={classes.largeButton}
                            color="primary"
                            onClick={() =>
                              remindMe(
                                this.props,
                                item,
                                getProductPageUrl(item)
                              )
                            }
                            disabled={productStore.notifiedItems.includes(
                              item.vendor_product_id
                            )}
                          >
                            Notify Me!
                          </Button>
                        </>
                      )}
                      {(!productIsAvailable(item) ||
                        !item.is_current_vendor) && (
                        <Button
                          variant="contained"
                          className={classnames(
                            classes.largeButton,
                            classes.similarProductBtn
                          )}
                          color="primary"
                          onClick={() =>
                            this.props.history.push(
                              getSearchPageUrl(trimStr(item.name, 13))
                            )
                          }
                        >
                          Browse similar products
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              )}
            </Paper>
            <Paper className={classes.dynamicHtml}>
              <Typography variant="h6" gutterBottom>
                About this item
              </Typography>
              <Typography variant="body2" component="div" gutterBottom>
                Buy {item.name} Online from GrocerApp. {item.name} Price in
                Pakistan is Rs.{" "}
                {isDeal(item) ? dealPrice(item) : productPrice(item)} at
                GrocerApp. Shop {item.name} Online &amp; Get delivery in Lahore,
                Islamabad, Rawalpindi and Faisalabad.
              </Typography>
            </Paper>

            {/* {item.desc && (
              <Paper className={classes.dynamicHtml}>
                <Typography variant="h6" gutterBottom>
                  About this item
                </Typography>
                <Typography variant="body2" component="div" gutterBottom>
                  <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                </Typography>
              </Paper>
            )}
            {item.desc_article && (
              <Suspense fallback={<div />}>
                <div className={classes.productDetails}>
                  <Paper
                    className={classnames(
                      classes.dynamicHtml,
                      classes.marginTop1
                    )}
                  >
                    <Grid
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="stretch"
                      className={classes.overflow}
                    >
                      <Grid item xs>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                        >
                          <Grid item className={classes.marginBottom1}>
                            <Typography variant="h6">
                              Product Details
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid xs item>
                        <Grid
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="stretch"
                          wrap="nowrap"
                          className={classes.horizontalContainer}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            gutterBottom
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item.desc_article
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </div>
              </Suspense>
            )} */}
            {lazyLoadComponentInViewPort(
              <Suspense fallback={<div />}>
                <ProductsHorizontalSectionPart
                  name={categoryName ? `More ${categoryName}` : "More"}
                  fetch={() => similarProductsStore.fetch(prid)}
                  cancel={similarProductsStore.cancel}
                  state={similarProductsStore.fetchState}
                  listName="Detail Page - Similar Products"
                  CTListName="Horizontal Section - Similar Products"
                  products={similarProductsStore.all}
                />
              </Suspense>,
              {
                once: true,
                resize: true,
                height: 200,
                style: { height: "100%" }
              }
            )}
            {lazyLoadComponentInViewPort(bottomHorizontalSection, {
              once: true,
              resize: true,
              height: 200,
              style: { height: "100%" }
            })}
            {/* <div className={classes.whyShopHeading}>
              <Typography variant="subtitle1" gutterBottom>
                Why shop from GrocerApp
              </Typography>
            </div>
            <Paper>
              <Grid
                container
                direction="column"
                justify="space-evenly"
                alignItems="stretch"
              >
                <Suspense fallback={<div />}>
                  <SalientFeaturesRowPart
                    icon={<AssignmentReturnedRounded />}
                    title={"Easy Returns & Refunds"}
                    text={
                      "Return products at doorstep and get refund in seconds."
                    }
                  />
                  <SalientFeaturesRowPart
                    icon={<MonetizationOnRounded />}
                    title={"Best Prices & Offers"}
                    text={
                      "Cheaper prices than your local supermarket, great cashback offers to top it off."
                    }
                  />
                </Suspense>
              </Grid>
            </Paper> */}
          </div>
        )}
        <SimpleDialogPart
          onClose={() => closeOutofStockPopup()}
          open={outOfStockPopup}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.relativePosition}
          >
            <IconButton
              aria-label="Close"
              className={classes.dialogCloseButton}
              onClick={closeOutofStockPopup}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" align="center" className={classes.heading}>
              Sorry, this product is not available at your location.
            </Typography>
            <Button
              className={classes.dialogButton}
              color="primary"
              variant="contained"
              onClick={() =>
                this.props.history.push(
                  getSearchPageUrl(trimStr(item.name, 13))
                )
              }
            >
              Browse similar products
            </Button>
          </Grid>
        </SimpleDialogPart>
      </>
    );
  }
}

export default withSnackbar(withCustomStyles(ProductDetailPage));
