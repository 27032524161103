import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import withCustomStyles from "./ProductListPage.style";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LoadMorePart from "../parts/LoadMorePart";
import { withRouter } from "react-router";
import Card from "@material-ui/core/Card";
import BreadcrumbsPart from "../parts/BreadcrumbsPart";
import { handleScroll } from "../utils/AppUtils";
import CategoryChipsPart from "../parts/CategoryChipsPart";
import ProductsListPart from "../parts/ProductsListPart";
import { productListDesc, productListTitle } from "../utils/SEOUtils";
import SEOInfoPart from "../parts/SEOInfoPart";
import {
  getCategoryPageUrl,
  getProductsPageAbsUrl,
  getProductsPageUrl
} from "../utils/UrlUtils";
import {
  getCategoryNamesTreeReverse,
  getListingPageName
} from "../utils/CategoryUtils";
import { Helmet } from "react-helmet";
import NotFoundPart from "../parts/NotFoundPart";
import ProductLoadingPart from "./ProductLoadingPart";
import CategoryChipPart from "../parts/CategoryChipPart";
import { withSnackbar } from "notistack";
import { _has } from "../utils/HelperUtils";

@inject(["productsStore"])
@inject(["categoriesStore"])
@observer
class ProductListPage extends Component {
  state = {
    categoryBrandSEO: {}
  };

  componentDidMount() {
    const { productsStore, match, categoriesStore } = this.props;
    this.fetchAll();
    this.scrollListener = handleScroll.bind(this, productsStore);
    if (_has(match, "params") && match.params.brand) {
      this.setState({
        categoryBrandSEO: categoriesStore.getCategoryBrandSEO(
          match.params.brand
        )
      });
    }
    window.addEventListener("scroll", this.scrollListener);
  }

  fetchAll() {
    const {
      productsStore,
      categoriesStore,
      enqueueSnackbar,
      match: {
        params: { category, brand }
      }
    } = this.props;
    let cid = categoriesStore.getCidBySlug(category);
    if (!categoriesStore.children(cid).length) {
      categoriesStore.fetchCategoryBrands(cid, error => {
        enqueueSnackbar(error.message, {
          variant: "error"
        });
      });
    }

    if (brand) {
      productsStore.fetchCatBrandsProducts(brand, cid);
    } else {
      productsStore.fetch(cid);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      productsStore,
      categoriesStore,
      match: {
        params: { category, brand }
      }
    } = this.props;
    if (
      (category !== prevProps.match.params.category ||
        prevProps.match.params.brand) &&
      !brand
    ) {
      productsStore.cancel();
      this.fetchAll();
    } else if (brand && brand !== prevProps.match.params.brand) {
      let cid = categoriesStore.getCidBySlug(category);
      productsStore.cancel();
      productsStore.fetchCatBrandsProducts(brand, cid);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
    this.cancelAll();
  }

  cancelAll() {
    const { productsStore, categoriesStore } = this.props;
    productsStore.cancel();
    categoriesStore.cancel();
  }

  render() {
    const {
      state: { categoryBrandSEO },
      props: {
        classes,
        productsStore,
        categoriesStore,
        match: { params },
        location: { pathname }
      }
    } = this;
    let cid = categoriesStore.getCidBySlug(params.category);

    const currentCategory = categoriesStore.find(cid);
    const seoDescription = productListDesc(currentCategory.name);
    const seoTitle = productListTitle(getCategoryNamesTreeReverse(cid));

    return (
      <>
        {productsStore.isFailed && <NotFoundPart />}
        {!productsStore.isFailed && (
          <div className={classes.minHeightPage}>
            <Helmet>
              <link
                rel="canonical"
                href={getProductsPageAbsUrl(currentCategory)}
              />
            </Helmet>
            <SEOInfoPart
              title={
                categoryBrandSEO.seo_title ||
                currentCategory.seo_title ||
                seoTitle
              }
              description={
                categoryBrandSEO.seo_description ||
                currentCategory.seo_description ||
                seoDescription
              }
              image={currentCategory.image}
              imageAlt={currentCategory.name}
              url={getProductsPageAbsUrl(currentCategory)}
            />
            <BreadcrumbsPart cid={cid} />
            <Paper className={classes.categories}>
              <CategoryChipPart
                label="All"
                to={getProductsPageUrl(currentCategory)}
                isSelected={pathname === getProductsPageUrl(currentCategory)}
              />
              {categoriesStore.children(cid).length > 0 ? (
                <CategoryChipsPart categories={categoriesStore.children(cid)} />
              ) : (
                categoriesStore.categoriesBrandsState !== "fetching" &&
                categoriesStore.categoriesBrands.length > 0 &&
                categoriesStore.categoriesBrands.map(brand => (
                  <CategoryChipPart
                    label={`${brand.name}`}
                    to={getCategoryPageUrl(brand, currentCategory)}
                    key={brand.id}
                    isSelected={brand.slug === params.brand}
                  />
                ))
              )}
            </Paper>
            <div className={classes.productsWrap}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
              >
                {productsStore.all.length === 0 &&
                  productsStore.state === "fetching" && <ProductLoadingPart />}
                {productsStore.all.length === 0 &&
                  productsStore.state === "done" && (
                    <Card>
                      <Typography variant="body1" color={"primary"}>
                        Couldn&apos;t find what you are looking, add it in order
                        comments and we&apos;ll get it for you!
                      </Typography>
                    </Card>
                  )}
                <ProductsListPart
                  listName={getListingPageName(currentCategory.id)}
                  CTListName={"Category Listing Page"}
                  products={productsStore.all}
                  state={productsStore.state}
                />
              </Grid>
            </div>
            {productsStore.all.length > 1 &&
              productsStore.state === "fetching" && <LoadMorePart />}
            {((categoryBrandSEO && categoryBrandSEO.seo_content) ||
              (currentCategory && currentCategory.seo_content)) && (
              <Paper className={classes.marginTop2}>
                <Grid item>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        categoryBrandSEO.seo_content ||
                        currentCategory.seo_content
                    }}
                  />
                </Grid>
              </Paper>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withSnackbar(withCustomStyles(ProductListPage)));
