import React from "react";
import { withRouter } from "react-router";
import { withSnackbar } from "notistack";
import { inject, observer } from "mobx-react";
import _throttle from "lodash/throttle";
import classnames from "classnames";
import withCustomStyles from "./OrderTilePart.style";
import { _get } from "../utils/HelperUtils";
import { getDeliveryTime, readableDate } from "../utils/DateUtils";
import {
  DELIVERY_STATUS_MESSAGES,
  PAYFAST_PAYMENT_FORM_CONTAINER
} from "../constatns/AppConstants";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import CalendarTodayRounded from "@material-ui/icons/CalendarTodayRounded";
import CloseRounded from "@material-ui/icons/CloseRounded";
import RetryIcon from "@material-ui/icons/Replay";
import ExpandIcon from "@material-ui/icons/ExpandLess";
import DoneRounded from "@material-ui/icons/DoneRounded";
import FiberNewRounded from "@material-ui/icons/FiberNewRounded";
import TimerRounded from "@material-ui/icons/TimerRounded";
import LoadMorePart from "./LoadMorePart";
import AddCardCvvPart from "./AddCardCvvPart";
import OrderDetailPart from "./OrderDetailPart";
import SimpleDialogPart from "./SimpleDialogPart";
import CreditCardIconPart from "./CreditCardIconPart";
import VerticalProductsListPart from "../parts/VerticalProductsListPart";
import Box from "@material-ui/core/Box";

@inject("myOrdersStore")
@inject("orderStore")
@inject("productStore")
@inject("uiStore")
@observer
class OrderTilePart extends React.Component {
  state = {
    openConfirmationModal: false,
    openModal: false,
    errorMessage: "",
    selectCvv: false,
    expanded: false,
    orderDetails: null
  };

  handleModalToggle = () => {
    const state = { openModal: !this.state.openModal };
    if (this.state.openModal) {
      state.errorMessage = "";
    }
    this.setState(state);
  };

  // eslint-disable-next-line no-unused-vars
  handlePaymentResponse = (isError, message, order) => {
    this.setState({ errorMessage: message, openModal: isError });
  };

  handleCvvModal = () => this.setState({ selectCvv: !this.state.selectCvv });

  handleRetryPayment = cvv => {
    const {
      props: { order, selectedCard, onRetryPayment },
      handleCvvModal,
      handlePaymentResponse
    } = this;
    const retryBody = {
      card_id: selectedCard.id,
      order_id: order.id,
      cvv
    };
    handleCvvModal();
    onRetryPayment(
      retryBody,
      order => handlePaymentResponse(false, "", order),
      error => {
        if (error && !error.approved) {
          handlePaymentResponse(true, error.message);
        }
      },
      () => {}
    );
  };

  fetchOrderInfo = cb => {
    const {
      props: { order, myOrdersStore, enqueueSnackbar }
    } = this;
    myOrdersStore.fetchOrderDetails(
      order.id,
      data => {
        this.setState({
          orderDetails: data
        });
        cb(data);
      },
      () => {
        enqueueSnackbar("Something went wrong", {
          variant: "error"
        });
      },
      () => {
        enqueueSnackbar("Something went wrong", {
          variant: "error"
        });
      }
    );
  };

  handleClick = () => {
    const {
      state: { expanded, orderDetails },
      fetchOrderInfo
    } = this;
    this.setState({
      expanded: !expanded
    });
    if (!orderDetails) {
      fetchOrderInfo(() => {});
    }
  };

  addAllProducts = _throttle(
    () => {
      const {
        props: {
          orderStore,
          productStore,
          listName,
          query,
          CTListName,
          enqueueSnackbar
        },
        state: { orderDetails },
        getProduct,
        fetchOrderInfo
      } = this;
      if (!orderDetails) {
        fetchOrderInfo(data => {
          if (data && data.order_items && data.order_items.found) {
            data.order_items.found.map(product => {
              productStore.setSelectedProduct(product);
              orderStore.addOne(
                getProduct(product),
                listName,
                CTListName,
                query
              );
            });
            enqueueSnackbar("Successfully added products to cart!", {
              variant: "success"
            });
          }
        });
      } else {
        if (
          orderDetails &&
          orderDetails.order_items &&
          orderDetails.order_items.found
        ) {
          orderDetails.order_items.found.map(product => {
            productStore.setSelectedProduct(product);
            orderStore.addOne(getProduct(product), listName, CTListName, query);
          });
          enqueueSnackbar("Successfully added products to cart!", {
            variant: "success"
          });
        }
      }
    },
    800,
    { trailing: false }
  );

  getProduct = product => {
    const {
      props: { orderStore }
    } = this;
    const orderStoreProduct = orderStore.all.get(product.id);
    if (
      orderStoreProduct &&
      orderStoreProduct.id &&
      product.order_quantity !== orderStoreProduct.order_quantity
    ) {
      return { ...product, order_quantity: orderStoreProduct.order_quantity };
    }
    return { ...product };
  };

  retryPayfastPayment = () => {
    const {
      props: { order, myOrdersStore },
      handleModalToggle
    } = this;

    handleModalToggle();
    myOrdersStore.retryPayfastPayment(order.id);
  };

  emptyCartAndAddProducts = _throttle(
    () => {
      const {
        props: { orderStore, listName, CTListName },
        addAllProducts
      } = this;
      orderStore.emptyCart(listName, CTListName);
      addAllProducts();
      this.setState({ openConfirmationModal: false });
    },
    1000,
    { trailing: false }
  );

  render() {
    const {
      props: {
        order,
        classes,
        retryOrderId,
        isFirstOrder,
        selectedCard,
        retryPaymentState,
        myOrdersStore,
        uiStore
      },
      state: {
        orderDetails,
        expanded,
        selectCvv,
        openModal,
        openConfirmationModal,
        errorMessage
      },
      handleRetryPayment,
      handleCvvModal,
      emptyCartAndAddProducts,
      handleModalToggle,
      retryPayfastPayment
    } = this;

    return (
      <Card className={classes.card}>
        {order.id && (
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid onClick={this.handleClick} className={classes.pointer}>
              <Grid item className={classes.mainRow}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item sm={4}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                    >
                      <Grid item className={classes.timer}>
                        <CalendarTodayRounded className={classes.fontSize2} />
                      </Grid>
                      <Grid item className={classes.marginRight1}>
                        <Typography variant="caption">
                          {readableDate(order.delivery_end_time)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={4}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.expandSection}
                    >
                      <ExpandIcon
                        className={{
                          [classes.rotate]: expanded,
                          [classes.rotateBack]: !expanded
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      className={classes.timerSection}
                    >
                      <Grid item className={classes.timer}>
                        <TimerRounded className={classes.fontSize2} />
                      </Grid>
                      <Grid item>
                        <Typography variant="caption">
                          {order.time_slot ||
                            getDeliveryTime(
                              order.delivery_start_time,
                              order.delivery_end_time
                            )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {!orderDetails &&
                myOrdersStore.fetchOrderDetailsState === "fetching" && (
                  <LoadMorePart />
                )}
              {!orderDetails &&
                myOrdersStore.fetchOrderDetailsState === "fail" && (
                  <Paper className={classes.retry}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item onClick={this.handleClick}>
                        <RetryIcon fontSize="large" color="primary" />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              {orderDetails && (
                <Paper className={classes.section}>
                  <VerticalProductsListPart
                    products={orderDetails.order_items.found}
                    tile={<OrderDetailPart />}
                  />
                  <Grid
                    container
                    justify="space-between"
                    alignContent="center"
                    direction="row"
                    className={classes.otherCharges}
                  >
                    <Grid>
                      <Typography variant={"subtitle2"}>Sub Total</Typography>
                    </Grid>
                    <Grid>
                      <Typography variant={"subtitle2"}>
                        Rs. {parseInt(orderDetails.amount)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justify="space-between"
                    alignContent="center"
                    direction="row"
                    className={classes.otherCharges}
                  >
                    <Grid>
                      <Typography variant={"subtitle2"}>
                        Delivery Charges
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography variant={"subtitle2"}>
                        Rs. {orderDetails.delivery_charges}
                      </Typography>
                    </Grid>
                  </Grid>
                  {orderDetails.wallet_amount_used ? (
                    <Grid
                      container
                      justify="space-between"
                      alignContent="center"
                      direction="row"
                      className={classes.otherCharges}
                    >
                      <Grid>
                        <Typography variant={"subtitle2"}>
                          {orderDetails.wallet_amount_used < 0
                            ? "Wallet Credit Used"
                            : "Pending Amount Paid"}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          variant={"subtitle2"}
                          color={
                            orderDetails.wallet_amount_used < 0
                              ? "secondary"
                              : "error"
                          }
                        >
                          Rs. {orderDetails.wallet_amount_used}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Paper>
              )}
            </Collapse>
            <Grid item className={classes.mainRow}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    spacing={1}
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="body2">
                        Order #{order.display_id}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        Payment Mode: {order.payment_status_message}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    spacing={1}
                    alignItems="flex-start"
                  >
                    <Grid item>
                      <Typography variant="body2">
                        {order.total_items}{" "}
                        {order.total_items > 1 ? "items" : "item"}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="subtitle2">
                        Rs. {order.application_total}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              justify="space-evenly"
              alignContent="center"
              direction="row"
              className={classes.mainRow}
            >
              <Button
                color="primary"
                variant="outlined"
                size={uiStore.screenWidth > 500 ? "large" : "small"}
                onClick={() => {
                  this.setState({ openConfirmationModal: true });
                }}
              >
                Load as New Cart
              </Button>
              <Button
                color="primary"
                variant="contained"
                size={uiStore.screenWidth > 500 ? "large" : "small"}
                onClick={() => {
                  this.addAllProducts();
                }}
              >
                Add to Cart
              </Button>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classnames(classes.gutter)}
            >
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  {(order.status === "declined" ||
                    order.status === "dorment") && (
                    <CloseRounded
                      className={classnames(classes.doneIcon, classes.red)}
                    />
                  )}
                  {order.status === "delivered" && (
                    <DoneRounded
                      className={classnames(classes.doneIcon, classes.green)}
                    />
                  )}
                  {isFirstOrder &&
                    order.status !== "delivered" &&
                    order.status !== "declined" &&
                    order.status !== "dorment" && (
                      <FiberNewRounded
                        className={classnames(classes.newIcon, classes.main)}
                      />
                    )}
                </Grid>
                <Grid item>
                  <Typography variant="body1" align={"center"}>
                    {_get(
                      DELIVERY_STATUS_MESSAGES,
                      order.status,
                      "Your order has been received"
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {order.payment_mode === "card" &&
                order.transaction_status === "FAILED" && (
                  <>
                    {retryPaymentState === "fetching" &&
                      retryOrderId === order.id && <LoadMorePart />}
                    {Boolean(order.should_retry_payment) && (
                      <Grid item className={classes.retryMain}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={retryPayfastPayment}
                        >
                          Retry Payment
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
            </Grid>
          </Grid>
        )}
        <SimpleDialogPart
          open={openModal}
          fullWidth
          title={errorMessage && "Error"}
          content={errorMessage}
          onClose={handleModalToggle}
        >
          {!errorMessage && (
            <Grid container>
              <LoadMorePart />
              <Box
                className={classes.payfastPaymentWrap}
                textAlign="center"
                id={PAYFAST_PAYMENT_FORM_CONTAINER}
              />
            </Grid>
            // <CreditCardListPart
            //   showTitleBar
            //   cards={cards}
            //   allowSelection
            //   isRetryPayment
            //   showCreditCardList
            //   title="Credit or Debit Card"
            //   orderId={order.id}
            //   selectedCard={selectedCard}
            //   onPayment={handlePaymentResponse}
            //   fetchCreditCards={fetchCreditCards}
            //   onPaymentSelection={onPaymentSelection}
            //   fetchCreditCardState={fetchCreditCardState}
            //   selectedPaymentMethod={selectedPaymentMethod}
            //   onToggleModal={() => {
            //     handleModalToggle();
            //     handleCvvModal();
            //   }}
            // />
          )}
        </SimpleDialogPart>
        {selectCvv && (
          <AddCardCvvPart
            open={selectCvv}
            card={selectedCard}
            cardIcon={<CreditCardIconPart type={selectedCard.scheme} />}
            onToggleModal={handleCvvModal}
            onAccept={handleRetryPayment}
          />
        )}
        <SimpleDialogPart
          title="Alert!"
          open={openConfirmationModal}
          onClose={() => {
            this.setState({ openConfirmationModal: false });
          }}
          content="The items in your current cart will be lost."
          isDownloadLinks={false}
          acceptBtnContent="OK"
          cancelBtnContent="Cancel"
          onAccept={emptyCartAndAddProducts}
          onCancel={() => {
            this.setState({ openConfirmationModal: false });
          }}
        />
      </Card>
    );
  }
}

export default withRouter(withSnackbar(withCustomStyles(OrderTilePart)));
