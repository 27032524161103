import { withStyles } from "@material-ui/core/styles";

const withSharedStyles = styles => {
  return withStyles(
    theme => {
      const _styles = styles(theme);
      return {
        colorWhite600: {
          color: theme.palette.common.white,
          fontWeight: 600
        },
        redColor: {
          color: theme.palette.common.red
        },
        captionColor: {
          color: theme.typography.caption.color
        },
        colorPrimary: {
          color: theme.palette.primary.main
        },
        colorBlack: {
          color: theme.palette.common.black
        },
        fontSize2: {
          fontSize: theme.spacing(2)
        },
        boldFont: {
          fontWeight: "bold"
        },
        backgroundBlack: {
          backgroundColor: theme.palette.common.black
        },
        backgroundWhite: {
          backgroundColor: theme.palette.common.white
        },
        noDecoration: {
          textDecoration: "none"
        },
        gutterBottom2: {
          paddingBottom: theme.spacing(2)
        },
        gutterBottomHalf: {
          paddingBottom: theme.spacing(0.5)
        },
        gutterTop0: {
          paddingTop: theme.spacing(0)
        },
        gutterTopHalf: {
          paddingTop: theme.spacing(0.5)
        },
        gutterTop1: {
          paddingTop: theme.spacing(1)
        },
        gutterTop2: {
          paddingTop: theme.spacing(1)
        },
        gutterBottom1: {
          paddingBottom: theme.spacing(1)
        },
        gutterBottom0: {
          paddingBottom: "0 !important"
        },
        marginBottom1: {
          marginBottom: theme.spacing(1)
        },
        marginBottom2: {
          marginBottom: theme.spacing(2)
        },
        marginBottom3: {
          marginBottom: theme.spacing(3)
        },
        padding0: {
          padding: "0 !important"
        },
        padding1: {
          padding: theme.spacing(1)
        },
        padding2: {
          padding: theme.spacing(2)
        },
        padding3: {
          padding: theme.spacing(3)
        },
        gutter: {
          padding: theme.spacing(1)
        },
        gutterTopDown: {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1)
        },
        paddingLeftRight0: {
          paddingLeft: 0,
          paddingRight: 0
        },
        margin2: {
          margin: theme.spacing(2)
        },
        margin1: {
          margin: theme.spacing(1)
        },
        marginHalf: {
          margin: theme.spacing(0.5)
        },
        hidden: {
          visibility: "hidden"
        },
        displayNone: {
          display: "none"
        },
        relativePosition: {
          position: "relative"
        },
        minHeightPage: {
          minHeight: "55vh"
        },
        fillHeight: {
          height: "100%"
        },
        textLineHeight: {
          maxHeight: 22,
          marginBottom: 2
        },
        marginLeft1: {
          marginLeft: theme.spacing(1)
        },
        marginLeft2: {
          marginLeft: theme.spacing(2)
        },
        floatRight: {
          float: "right"
        },
        marginRight1: {
          marginRight: theme.spacing(1)
        },
        marginRight2: {
          marginRight: theme.spacing(2)
        },
        marginRightHalf: {
          marginRight: theme.spacing(0.5)
        },
        marginBottomHalf: {
          marginBottom: theme.spacing(0.5)
        },
        marginLeftHalf: {
          marginLeft: theme.spacing(0.5)
        },
        marginTopHalf: {
          marginTop: theme.spacing(0.5)
        },
        marginTop2: {
          marginTop: theme.spacing(2)
        },
        marginTop1: {
          marginTop: theme.spacing(1)
        },
        displayInline: {
          display: "inline-flex"
        },
        center: {
          textAlign: "center"
        },
        mainRow: {
          borderTop: theme.mixins.borderLine2,
          padding: theme.spacing(1)
        },
        borderLine3Bottom: {
          borderBottom: theme.mixins.borderLine3
        },
        borderLine2Bottom: {
          borderBottom: theme.mixins.borderLine2
        },
        borderLine1Bottom: {
          borderBottom: theme.mixins.borderLine1
        },
        borderLine2Top: {
          borderTop: theme.mixins.borderLine2
        },
        borderLine3Top: {
          borderTop: theme.mixins.borderLine3
        },
        borderLine1Top: {
          borderTop: theme.mixins.borderLine1
        },
        subRow: {
          borderTop: theme.mixins.borderLine1,
          padding: theme.spacing(1)
        },
        section: {
          padding: theme.spacing(1),
          marginBottom: theme.spacing(2)
        },
        largeIcon: {
          fontSize: theme.spacing(15)
        },
        largeButton: {
          width: "100%",
          padding: "12px 16px",
          fontWeight: 600
        },
        midButton: {
          width: "100%",
          padding: "8px 16px",
          fontWeight: "bold",
          fontSize: "0.775rem"
        },
        centerAlignedImage: {
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%"
        },
        fullWidth: {
          width: "100%"
        },
        flexWrap: {
          flexWrap: "wrap"
        },
        noWrap: {
          flexWrap: "nowrap"
        },
        weight600: {
          fontWeight: 600
        },
        darkerColor: {
          color: "rgba(0, 0, 0, 0.87)"
        },
        hyphenateText: {
          hyphens: "auto"
        },
        shadows: {
          boxShadow:
            "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"
        },
        payfastPaymentWrap: {
          margin: "auto",
          textAlign: "center",
          "& form": {
            height: 0
          }
        },
        ..._styles
      };
    },
    { withTheme: true }
  );
};

export default withSharedStyles;
