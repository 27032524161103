import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(() => ({
  link: {
    cursor: "pointer"
  },
  breadcrumbText: {
    color: "rgba(0, 0, 0, 0.54) !important",
    fontSize: "0.875rem",
    fontWeight: 400
  },
  nonClickable: {
    textDecoration: "none !important",
    fontSize: "0.875rem"
  }
}));

export default withCustomStyles;
