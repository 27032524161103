import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  cssLabel: {
    "&$cssFocused": {
      color: theme.palette.primary.main
    }
  },
  cssFocused: {},
  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: theme.palette.primary.main
    }
  },
  notchedOutline: {},
  inputRoot: {
    width: "100%",
    color: "rgba(0, 0, 0, 0.54) !important"
  },
  root: {
    padding: theme.spacing(1)
  }
}));

export default withCustomStyles;
