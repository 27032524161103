import { PAYFAST_PAYMENT_FORM_CONTAINER } from "../constatns/AppConstants";
import { parseStringToHtml } from "./AppUtils";
import { weekDay } from "./DateUtils";

export const getStandardAnalyticsOrder = order => {
  const timeSlot = order.timeSlot.split("||");

  return {
    id: order.id,
    revenue: order.application_total || order.totalBill || 0, // Total transaction value (incl. tax and shipping)
    shipping: order.delivery_charges || order.deliveryCharges,
    coupon: order.promo_code,
    first_purchase: order.first_purchase,
    payment_method: order.paymentMethod,
    slot_day: weekDay(timeSlot[1]),
    slot_start_time: timeSlot[1],
    slot_end_time: timeSlot[2]
  };
};

/**
 * This function redirect to payfast payment page for order payment
 * @param paymentFormString stringify payfast payment form
 * @step Parsing stringify payfast payment form to html
 * @step Render parsed payment form in the given html element
 * @step After rendering payment form we submit payment form for redirecting user to payfast payment page
 */
export const redirectToPayfastPage = paymentFormString => {
  const parsedHTML = parseStringToHtml(paymentFormString);
  const paymentFormWrap = document.getElementById(
    PAYFAST_PAYMENT_FORM_CONTAINER
  );
  const paymentFormNodes = Array.from(parsedHTML.body.childNodes);
  paymentFormNodes.forEach(node => {
    paymentFormWrap.appendChild(node.cloneNode(true));
  });
  // getting payment form by id, mentioned in payment response
  const paymentForm = document.getElementById("PayFast_payment_form");
  paymentForm.submit();
};
