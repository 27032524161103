import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  largeButton: {
    width: "100%",
    padding: "8px 16px",
    border: theme.mixins.borderLine6,
    fontWeight: 600,
    marginBottom: theme.spacing(1)
  },
  card: {
    border: theme.mixins.borderLine2,
    padding: theme.spacing(1),
    width: "100%"
  },
  horizontalMinHeight: {
    minHeight: 49
  },
  tileMinHeight: {
    [theme.breakpoints.up(330)]: {
      minHeight: 47
    },
    [theme.breakpoints.down(600)]: {
      minHeight: 72
    }
  },
  cardMediaItem: {
    minHeight: 130,
    [theme.breakpoints.up("sm")]: {
      minHeight: 150
    },
    minWidth: "100%",
    marginBottom: theme.spacing(1),
    position: "relative"
  },
  cardMedia: {
    maxHeight: 130,
    maxWidth: 130,
    [theme.breakpoints.up("sm")]: {
      maxHeight: 150,
      maxWidth: 150
    }
  },
  cardMediaHorizontal: {
    maxHeight: 130,
    maxWidth: 130,
    minHeight: 130,
    minWidth: 130,
    [theme.breakpoints.up("sm")]: {
      maxHeight: 150,
      maxWidth: 150
    }
  },
  outOfStock: {
    filter: "grayscale(1)",
    fontWeight: 750,
    marginBottom: theme.spacing(1),
    padding: "10px 20px",
    minHeight: "42px"
  },
  outOfStockSVG: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: "auto",
    width: "100px",
    height: "100px",
    opacity: 0.75
  },
  dealFlash: {
    backgroundColor: "red",
    borderRadius: 20,
    display: "inline-block",
    lineHeight: "1",
    visibility: "initial",
    marginTop: "1px",
    position: "relative",
    zIndex: "1",
    textTransform: "uppercase",
    top: "0px",
    left: "0px",
    padding: `${theme.spacing(1) / 2}px ${theme.spacing(1)}px`,
    [theme.breakpoints.down(600)]: {
      padding: "3px 4px",
      fontSize: "0.65rem",
      marginLeft: theme.spacing(1) / 2 - 2
    }
  },
  bgShape1: {
    backgroundColor: "red",
    width: "43%",
    position: "relative",
    display: "inline-block",
    height: 21,
    "&::before": {
      content: "''",
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "0 0 21px 11px",
      borderColor: "transparent transparent #FED831 transparent",
      right: 0,
      top: 0
    }
  },
  bgShape2: {
    backgroundColor: "#FED831",
    width: "57%",
    position: "relative",
    display: "inline-block",
    height: 21,
    "&::before": {
      content: "''",
      position: "absolute",
      borderStyle: "solid",
      borderWidth: "0 0 21px 11px",
      borderColor: "transparent transparent #ffffff transparent",
      right: 0,
      top: 0
    }
  },
  flashDealIcon: {
    color: "#FED831",
    position: "absolute",
    left: -4,
    top: -7,
    width: 21,
    height: 21
  },
  flashDeal: {
    fontStyle: "italic",
    fontWeight: "500",
    padding: "4px 4px 4px 0px",
    textAlign: "center"
  },
  timerText: {
    color: "#000",
    fontWeight: "500",
    padding: "4px 6px 4px 0px",
    textAlign: "center"
  },
  normalFont: {
    fontSize: "0.65rem",
    [theme.breakpoints.down(400)]: {
      fontSize: "0.55rem"
    }
  },
  horizontalFont: {
    fontSize: "0.65rem"
  },
  flashTextWrap: {
    flexBasis: 0,
    minHeight: 23,
    maxWidth: 200,
    position: "relative",
    width: "105%",
    left: -8
  },
  priceText: {
    color: "red",
    fontWeight: 700,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(600)]: {
      fontSize: "0.8rem",
      marginLeft: theme.spacing(1) / 2 - 2
    }
  },
  hiddenDeal: {
    visibility: "hidden",
    lineHeight: "1",
    marginTop: "1px",
    position: "relative",
    zIndex: "1",
    top: "0px",
    left: "0px",
    padding: `${theme.spacing(1) / 4}px ${theme.spacing(2)}px`
  },
  nonDealPrice: {
    textDecoration: "line-through",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(600)]: {
      fontSize: "0.57rem",
      marginLeft: theme.spacing(1) / 2 - 2
    }
  },
  textLineHeight: {
    maxHeight: 22
  },
  discountHeight: {
    maxHeight: 10,
    marginBottom: 20
  }
}));

export default withCustomStyles;
