import * as dayjs from "dayjs";

const diffFromToday = date => {
  return date.startOf("day").diff(dayjs().startOf("day"), "days");
};

const isToday = date => {
  return diffFromToday(date) === 0;
};

const isTomorrow = date => {
  return diffFromToday(date) === 1;
};

export const dayjsDate = date => {
  return dayjs(date);
};

export const weekDay = date => {
  const paramDate = dayjsDate(date);
  if (isToday(paramDate)) {
    return "Today";
  }
  if (isTomorrow(paramDate)) {
    return "Tomorrow";
  }
  return paramDate.format("ddd");
};

export const dayMonth = date => {
  return dayjsDate(date).format("MMM D");
};

export const getDeliveryTime = (start, end) => {
  const startTime = dayjsDate(start).format("h:mm a");
  const endTime = dayjsDate(end).format("h:mm a");
  return `${startTime} - ${endTime}`;
};

export const getSlotDayTimeId = slotTime => {
  return slotTime.id + "||" + slotTime.start + "||" + slotTime.end;
};

export const readableDate = date => {
  return dayjsDate(date).format("ddd, D MMM YYYY");
};

export const readableTime = date => {
  return dayjsDate(date).format("h:mm a");
};

export function isSameOrAfterNow(date) {
  return new Date() <= new Date(date);
}

export function isBetweenDates(startDate, endDate) {
  return new Date() < new Date(endDate) && new Date() >= new Date(startDate);
}

export function timeDiffBreakdown(endDate) {
  let total_seconds = dayjsDate(endDate).diff(new Date(), "second");
  let minute = Math.trunc(total_seconds / 60);
  let hour = Math.trunc(minute / 60);
  let day = Math.trunc(hour / 24);
  if (day > 0) {
    day = dayMonth(endDate);
  }
  let timeDiff = {
    day,
    hour,
    minute: minute - hour * 60,
    second: total_seconds - Math.trunc(minute) * 60
  };
  return timeDiff;
}
