import categoriesStore from "./CategoriesStore";
import productsStore from "./ProductsStore";
import productStore from "./ProductStore";
import topSellingStore from "./TopSellingStore";
import slidersStore from "./SlidersStore";
import dealsStore from "./DealsStore";
import searchProductsStore from "./SearchProductsStore";
import routerStore from "./RouterStore";
import orderStore from "./OrdersStore";
import uiStore from "./UIStore";
import faqStore from "./FAQStore";
import customerStore from "./CustomerStore";
import myOrdersStore from "./MyOrdersStore";
import similarProductsStore from "./SimilarProductsStore";
import featuredProductsStore from "./FeaturedProductsStore";
import brandStore from "./BrandStore";
import allBrandsStore from "./AllBrandsStore";
import globalSettingStore from "./GlobalSettingStore";
import paymentStore from "./PaymentStore";
import memberShipStore from "./MemberShipStore";
import userBasedRecommendationsStore from "./UserBasedRecommendationsStore";
import productBasedRecommendationsStore from "./ProductBasedRecommendationsStore";
import locationStore from "./LocationStore";

const stores = {
  routerStore,
  categoriesStore,
  searchProductsStore,
  productsStore,
  orderStore,
  productStore,
  dealsStore,
  slidersStore,
  topSellingStore,
  allBrandsStore,
  brandStore,
  featuredProductsStore,
  similarProductsStore,
  uiStore,
  faqStore,
  paymentStore,
  memberShipStore,
  customerStore,
  myOrdersStore,
  globalSettingStore,
  userBasedRecommendationsStore,
  productBasedRecommendationsStore,
  locationStore
};

export default stores;
