import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  root: {
    minHeight: "800px"
  },
  categories: {
    minHeight: 65,
    borderBottom: theme.mixins.borderLine3,
    padding: theme.spacing(1),
    overflow: "scroll",
    whiteSpace: "nowrap"
  },
  productsLoadMore: {
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(40)
  },
  navigationLoadMore: {
    minHeight: theme.spacing(16),
    paddingTop: theme.spacing(8)
  },
  productsWrap: {
    minHeight: 750
  }
}));

export default withCustomStyles;
