import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  listItem: {
    borderBottom: theme.mixins.borderLine3,
    textDecoration: "none",
    cursor: "pointer"
  },
  drawer: {
    "& > div": {
      minWidth: 250,
      padding: 0
    }
  },
  closeIcon: {
    textDecoration: "none",
    display: "flex",
    justifyContent: "flex-end",
    height: "30px",
    "& > button": {
      width: "30px"
    },
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  name: {
    maxWidth: 250
  },
  drawerChild: {
    padding: theme.spacing(2.5)
  }
}));

export default withCustomStyles;
