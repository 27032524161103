import { action, computed, observable } from "mobx";
import { postRequest, getRequest } from "../utils/RestMiddlewareUtil";
import customerStore from "./CustomerStore";
import gtmService from "../services/GTMService";

class ProductStore {
  @observable item = {};
  @observable notifiedItems = [];
  @observable currentlyNotified = false;
  @observable fetchState = "pending";
  @observable notifyRequestState = "pending";
  @observable notificationClickState = "pending";
  @observable selectedItem = {};
  @observable state = "pending";

  promise;

  @computed get vendorId() {
    return customerStore.vendorId;
  }

  @computed get customer() {
    return customerStore.customer;
  }

  @action
  toggleCurrentlyNotified() {
    this.currentlyNotified = !this.currentlyNotified;
  }

  @action
  addToNotified(productId) {
    if (!productId) return;
    this.notifiedItems.push(productId);
  }

  @action
  removeFromNotified(productId) {
    if (!productId) return;
    this.notifiedItems = this.notifiedItems.filter(id => id !== productId);
  }

  @action
  setProduct(product) {
    if (!product) return;
    this.item = product;
  }

  @action
  setSelectedProduct(product) {
    if (!product) return;
    this.selectedItem = product;
  }

  @action
  cancelPromise() {
    if (this.promise) {
      this.promise.cancel();
    }
  }

  @computed get isFailed() {
    return this.state === "fail" || this.state === "error";
  }

  @computed get isSuccess() {
    return this.state === "done";
  }

  @computed get isFetching() {
    return this.state === "fetching";
  }

  @computed get isProductDetailClicked() {
    return this.item.id && this.item.id === this.selectedItem.id;
  }

  @action
  notifyRequest = (vendorProductId, success = () => {}, fail = () => {}) => {
    if (!vendorProductId) {
      throw new Error("Missing productId");
    }
    this.cancelPromise("notifyRequest");
    this.notifyRequestState = "fetching";
    const body = {
      vendor_product_id: vendorProductId,
      source: "web"
    };
    this.notifyRequestPromise = postRequest(
      this,
      "v1/product-stock-reminder",
      body,
      data => {
        success(data);
        this.notifyRequestState = "done";
      },
      () => {
        fail();
        this.notifyRequestState = "fail";
      },
      () => {
        fail();
        this.notifyRequestState = "error";
      }
    );
  };

  @action
  notificationClick = (
    vendorProductId,
    success = () => {},
    fail = () => {}
  ) => {
    if (!vendorProductId) {
      throw new Error("Missing productId");
    }
    this.cancelPromise("notificationClick");
    this.notificationClickState = "fetching";
    const body = {
      vendor_product_id: vendorProductId
    };
    this.notificationClickPromise = postRequest(
      this,
      "v1/product-stock-reminded",
      body,
      () => {
        success();
        this.notificationClickState = "done";
      },
      () => {
        fail();
        this.notificationClickState = "fail";
      }
    );
  };

  @action
  fetch(productId, success = () => {}, fail = () => {}) {
    if (!productId) {
      throw new Error("Missing productId");
    }
    this.cancelPromise("fetch");
    getRequest(
      this,
      `v1/products/web/detail?vendor_id=${this.vendorId}&product_id=${productId}`,
      data => {
        this.item = data;
        if (!data) {
          this.state = "fail";
        }
        success(data);
        gtmService.productDetail(data, this.customer);
      },
      () => {
        fail();
      }
    );
  }

  @action
  cancel() {
    this.product = {};
    this.state = "pending";
    if (this.promise) {
      this.promise.cancel();
    }
  }
}

const productStore = new ProductStore();
export default productStore;
