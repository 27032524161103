import React from "react";
import withCustomStyles from "./BreadcrumbsListSchemaPart.style";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { getProductsPageAbsUrl } from "../utils/UrlUtils";

const BreadcrumbsListSchemaPart = props => {
  const { categories } = props;

  const itemsList = categories.map(
    (category, index) =>
      `{
      "@type": "ListItem",
      "position": ${index + 1},
      "name": "${category.name}",
      "item": "${getProductsPageAbsUrl(category)}"
    }`
  );

  const noCategoryCase = `{
    "@type": "ListItem",
    "position": 1,
    "name": "Categories",
    "item": "https://grocerapp.pk/categories"
  }`;

  return (
    <Helmet>
      <script type="application/ld+json">
        {`{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [${categories.length > 0 ? itemsList : noCategoryCase}]
    }`}
      </script>
    </Helmet>
  );
};

export default withRouter(withCustomStyles(BreadcrumbsListSchemaPart));
