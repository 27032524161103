import React from "react";
import PropTypes from "prop-types";

const CreateListPart = ({ count, children }) => {
  return [...Array(count).keys()].map(key => (
    <React.Fragment key={key}>{children}</React.Fragment>
  ));
};

CreateListPart.propTypes = {
  count: PropTypes.number.isRequired,
  inline: PropTypes.bool
};

export default CreateListPart;
