import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  root: {
    borderRadius: theme.spacing(1) / 2,
    margin: `${theme.spacing(2)}px 0`
  },
  item: {
    minWidth: "150px",
    padding: theme.spacing(1)
  },
  categoriesCardMedia: {
    minHeight: "60px",
    minWidth: "80px",
    maxHeight: 100,
    maxWidth: 100
  },
  displayFlex: {
    display: "flex"
  },
  justifyCenter: {
    display: "flex",
    justifyContent: "center"
  },
  horizontalContainer: {
    [theme.breakpoints.down(700)]: {
      overflowX: "scroll"
    },
    width: "100%",
    padding: `${theme.spacing(1)}px 0`
  },
  scrollbarColor: {
    "& > div::-webkit-scrollbar": {
      width: theme.spacing(1.2),
      height: theme.spacing(1.2),
      borderRadius: theme.spacing(2.5),
      backgroundColor: "#F5F5F5"
    },
    "& > div::-webkit-scrollbar-track": {
      background: "#F5F5F5",
      borderRadius: theme.spacing(1.2)
    },
    "& > div::-webkit-scrollbar-thumb": {
      backgroundColor: "#9c9c9c" /* color of the scroll thumb */,
      borderRadius: theme.spacing(2.5)
    }
  }
}));

export default withCustomStyles;
