import { action, autorun, observable, toJS } from "mobx";
import { loadData, saveData } from "../utils/StoreUtils";
import { DEFAULT_DEBOUNCE } from "../constatns/AppConstants";
import customerStore from "./CustomerStore";
import { getRequest, postRequest } from "../utils/RestMiddlewareUtil";

class LocationStore {
  @observable all = [];
  @observable editAddressState = "pending";
  @observable createAddressState = "pending";
  @observable deleteAddressState = "pending";
  @observable getAddressesState = "pending";
  @observable selectAddressState = "pending";
  @observable currentAddress = {};
  @observable addressToEdit = {};
  @observable pinToEdit = {};
  @observable pinDetails = {};
  @observable locationDetails = {};
  @observable house = "";
  @observable street = "";
  @observable block = "";
  @observable landmark = "";
  @observable selected = "";
  @observable addressLabel = "";
  promise;
  disposeAutorun;

  constructor() {
    this.persistData();
  }

  @action
  resetLocationData = () => {
    this.all = [];
    this.currentAddress = {};
  };

  @action
  setLocationDetails = location => {
    this.locationDetails = { ...this.locationDetails, ...location };
  };

  @action
  setPinDetails = location => {
    this.pinDetails = location;
  };

  @action
  setAddressToEdit = address => {
    if (address) {
      let lat = parseFloat(address.location_coordinates.split(",")[0]);
      let lng = parseFloat(address.location_coordinates.split(",")[1]);
      this.pinToEdit = { lat, lng };
    }
    this.addressToEdit = address;
  };

  @action
  cancel = () => {
    this.state = "pending";
    if (this.promise) {
      this.promise.cancel();
    }
    this.cancelPromise("createAddress");
    this.cancelPromise("getAddresses");
    this.cancelPromise("selectAddress");
    this.cancelPromise("editAddress");
  };

  @action
  cancelPromise = callee => {
    if (this[callee + "Promise"]) {
      this[callee + "Promise"].cancel();
    }
    this[callee + "State"] = "pending";
  };

  @action
  createAddress = (
    body,
    success = () => {},
    fail = () => {},
    error = () => {}
  ) => {
    this.cancelPromise("createAddress");
    this.createAddressState = "fetching";
    this.createAddressPromise = postRequest(
      this,
      "v1/customer/address/create",
      body,
      data => {
        success(data);
        this.createAddressState = "done";
      },
      data => {
        fail(data);
        this.createAddressState = "fail";
      },
      e => {
        error(e);
        this.createAddressState = "error";
      }
    );
  };

  @action
  deleteAddress = (
    id,
    success = () => {},
    fail = () => {},
    error = () => {}
  ) => {
    this.cancelPromise("deleteAddress");
    this.deleteAddressState = "fetching";

    const body = {
      address_id: id
    };

    this.deleteAddressPromise = postRequest(
      this,
      "v1/customer/address/delete",
      body,
      data => {
        success(data);
        this.deleteAddressState = "done";
      },
      data => {
        fail(data);
        this.deleteAddressState = "fail";
      },
      e => {
        error(e);
        this.deleteAddressState = "error";
      }
    );
  };

  @action
  getAddresses = (success = () => {}, fail = () => {}) => {
    if (!customerStore.isLoggedIn) {
      return;
    }
    this.cancelPromise("getAddresses");
    this.getAddressesState = "fetching";
    this.getAddressesPromise = getRequest(
      this,
      "v1/customer/address/get",
      data => {
        this.all = data;
        if (this.all.length > 0) {
          this.currentAddress = this.all.find(address => address.is_selected);
        }
        this.getAddressesState = "done";
        success(data);
      },
      () => {
        this.getAddressesState = "fail";
        fail();
      },
      () => {
        this.getAddressesState = "error";
        fail();
      }
    );
  };

  @action
  selectAddress = (id, success = () => {}, fail = () => {}) => {
    this.cancelPromise("selectAddress");
    this.selectAddressState = "fetching";
    this.selectAddressPromise = postRequest(
      this,
      "v1/customer/address/select",
      { address_id: id },
      data => {
        this.currentAddress = data.address;
        this.all.forEach((address, index) => {
          if (address.is_selected === true) {
            this.all[index].is_selected = false;
          } else if (address.id === data.address.id) {
            this.all[index].is_selected = true;
          }
        });
        this.selectAddressState = "done";
        success(data);
      },
      () => {
        this.selectAddressState = "fail";
        fail();
      },
      () => {
        this.selectAddressState = "error";
        fail();
      }
    );
  };

  @action
  editAddress = (
    body,
    success = () => {},
    fail = () => {},
    error = () => {}
  ) => {
    this.cancelPromise("editAddress");
    this.editAddressState = "fetching";
    this.editAddressPromise = postRequest(
      this,
      "v1/customer/address/edit",
      body,
      data => {
        success(data);
        this.editAddressState = "done";
      },
      data => {
        fail(data);
        this.editAddressState = "fail";
      },
      e => {
        error(e);
        this.editAddressState = "error";
      }
    );
  };

  cancelAndDisposeAutorun = () => {
    this.cancel();
    this.disposeAutorun();
  };

  persistData = () => {
    const key = "locationStore";
    loadData(this, key, data => {
      this.all = Array.isArray(data.all) ? data.all : [];
      this.currentAddress = data.currentAddress;
    });
    this.disposeAutorun = autorun(
      () => {
        saveData(this, key, {
          all: toJS(Array.isArray(this.all) ? this.all : []),
          currentAddress: toJS(this.currentAddress)
        });
      },
      { delay: DEFAULT_DEBOUNCE }
    );
  };
}

const locationStore = new LocationStore();
export default locationStore;
