import React, { Component, lazy, Suspense } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router";
import * as Sentry from "@sentry/browser";
import gtmService from "./services/GTMService";
import {
  consoleError,
  isBrandPage,
  isEasyPaisaMiniApp
} from "./utils/AppUtils";
import { inject, observer } from "mobx-react";
import PrivateRouteService from "./services/PrivateRouteService";
import "lazysizes";
import _startsWith from "lodash/startsWith";
import { when } from "mobx";
import FullPageLoaderPart from "./parts/FullPageLoaderPart";
import LoadMorePart from "./parts/LoadMorePart";
import { getQueryParam, isStandalonePage } from "./utils/UrlUtils";
import componentLoader from "./utils/ComponentLoader";
import HomePage from "./pages/HomePage";
import ProductDetailPage from "./pages/ProductDetailPage";
import ProductListPage from "./pages/ProductListPage";
import ZendeskChat, { ZendeskAPI } from "react-zendesk";

const CategoriesPage = lazy(() =>
  componentLoader(import("./pages/CategoriesPage"))
);
const RedirectPage = lazy(() =>
  componentLoader(import("./pages/RedirectPage"))
);
const CartPage = lazy(() => componentLoader(import("./pages/CartPage")));
const SearchPage = lazy(() => componentLoader(import("./pages/SearchPage")));
const NotFoundPage = lazy(() =>
  componentLoader(import("./pages/NotFoundPage"))
);
const TermsPage = lazy(() => componentLoader(import("./pages/TermsPage")));
const FAQsPage = lazy(() => componentLoader(import("./pages/FAQsPage")));
const MobilePage = lazy(() => componentLoader(import("./pages/MobilePage")));
const AboutUsPage = lazy(() => componentLoader(import("./pages/AboutUsPage")));
const DiscountPromoPage = lazy(() =>
  componentLoader(import("./pages/DiscountPromoPage"))
);
const PrivacyPolicyPage = lazy(() =>
  componentLoader(import("./pages/PrivacyPolicyPage"))
);
const ProfilePage = lazy(() => componentLoader(import("./pages/ProfilePage")));
const LoginPage = lazy(() => componentLoader(import("./pages/LoginPage")));
const CheckoutPage = lazy(() =>
  componentLoader(import("./pages/CheckoutPage"))
);
const MyOrdersPage = lazy(() =>
  componentLoader(import("./pages/MyOrdersPage"))
);
const ReturnRefundPolicyPage = lazy(() =>
  componentLoader(import("./pages/ReturnRefundPolicyPage"))
);
const ChangeLocationPage = lazy(() =>
  componentLoader(import("./pages/ChangeLocationPage"))
);
const SavedLocationsPage = lazy(() =>
  componentLoader(import("./pages/SavedLocationsPage"))
);
const AddAddressPage = lazy(() =>
  componentLoader(import("./pages/AddAddressPage"))
);
const EditAddressPage = lazy(() =>
  componentLoader(import("./pages/EditAddressPage"))
);
const BrandPage = lazy(() => componentLoader(import("./pages/BrandPage")));
const AllBrandsPage = lazy(() =>
  componentLoader(import("./pages/AllBrandsPage"))
);
const ThreeDSSuccessPaymentPage = lazy(() =>
  componentLoader(import("./pages/ThreeDSSuccessPaymentPage"))
);
const WalletPage = lazy(() => componentLoader(import("./pages/WalletPage")));
const AddCardPage = lazy(() => componentLoader(import("./pages/AddCardPage")));
const MemberShipPage = lazy(() =>
  componentLoader(import("./pages/MemberShipPage"))
);
const CategoriesSiteMapPage = lazy(() =>
  componentLoader(import("./pages/CategoriesSiteMapPage"))
);
const ProductSiteMapPage = lazy(() =>
  componentLoader(import("./pages/ProductSiteMapPage"))
);
const UnsubscribePage = lazy(() =>
  componentLoader(import("./pages/UnsubscribePage"))
);
const FooterPart = lazy(() => componentLoader(import("./parts/FooterPart")));

@inject(["routerStore"])
@inject(["uiStore"])
@inject(["orderStore"])
@inject(["customerStore"])
@inject(["globalSettingStore"])
@inject(["allBrandsStore"])
@inject(["categoriesStore"])
@observer
class RouteWrapper extends Component {
  cancelAll = () => {
    this.props.allBrandsStore.cancel();
  };

  componentDidUpdate(prevProps) {
    const {
      props: { uiStore }
    } = this;
    document
      .getElementsByTagName("body")[0]
      .style.setProperty("height", "100%", "important");
    const brandPageHistory =
      isBrandPage(prevProps.location.pathname) &&
      isBrandPage(this.props.location.pathname);

    if (this.props.location !== prevProps.location && !brandPageHistory) {
      window.scrollTo(0, 0);
    }

    if (window && window.innerWidth) {
      uiStore.setScreenWidth(window.innerWidth);
    }
  }

  handleResize = uiStore => {
    uiStore.setScreenWidth(window.innerWidth);
  };

  handleChatLoad = () => {
    if ("zE" in window) {
      ZendeskAPI("webWidget", "hide");
      ZendeskAPI("webWidget:on", "userEvent", function(event) {
        if (event.action === "Web Widget Minimised") {
          ZendeskAPI("webWidget", "hide");
        }
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeListener);
    this.cancelAll();
  }

  componentDidMount() {
    const {
      props: {
        location,
        history,
        routerStore,
        customerStore,
        globalSettingStore,
        uiStore,
        location: { search }
      }
    } = this;
    globalSettingStore.fetch();

    if (_startsWith(location.pathname, "/redirect")) {
      return;
    }

    this.resizeListener = this.handleResize.bind(this, uiStore);
    window.addEventListener("resize", this.resizeListener);

    gtmService.pageView(location.pathname + location.search);
    routerStore.setCurrentPath(location.pathname + location.search);

    when(
      () =>
        isEasyPaisaMiniApp() &&
        getQueryParam(search, "openId") &&
        customerStore.isCustomerLoaded &&
        customerStore.isTokenLoaded &&
        !customerStore.isLoggedIn,
      () =>
        history.replace(
          `/login?openId=${getQueryParam(search, "openId")}&redirect=/`
        )
    );

    history.listen(location => {
      const fullPath = location.pathname + location.search;
      routerStore.setCurrentPath(fullPath);
      gtmService.pageView(fullPath);
    });
  }

  componentDidCatch(error, errorInfo) {
    consoleError(error);
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const {
      props: {
        customerStore,
        categoriesStore,
        location: { pathname }
      },
      handleChatLoad
    } = this;

    const shouldLoadMainSite = !!customerStore.vendor.id;

    if (customerStore.isLoggedIn) {
      gtmService.userId(customerStore.customer.id);
    }

    return (
      <React.Fragment>
        {_startsWith(pathname, "/redirect") || shouldLoadMainSite ? (
          <>
            <Suspense fallback={<LoadMorePart />}>
              <Switch>
                {/* client side redirects for new urls */}
                <Redirect
                  from="/prn/:prn/prid/:prid"
                  to="/products/:prn-:prid/"
                />
                <Redirect from="/shop/:slug/:cn/:cid" to="/:cn/:slug/" />
                <Redirect from="/cn/:cn/cid/:cid" to="/:cn/" />
                <Redirect from="/cn/:slug/:cn/:cid" to="/:cn/:slug/" />
                <Redirect from="/sitemap/cn/:cn/cid/:cid" to="/sitemap/:cn/" />
                {/* redirects end */}

                <Route exact path="/" component={HomePage} />
                <Route path="/login*" component={LoginPage} />
                <Route
                  exact
                  path="/return-and-refund-policy"
                  component={ReturnRefundPolicyPage}
                />
                <Route exact path="/mobile-app/" component={MobilePage} />
                <Route exact path="/about-us/" component={AboutUsPage} />
                <Route
                  exact
                  path="/promo-and-discounts/"
                  component={DiscountPromoPage}
                />
                <Route exact path="/faqs" component={FAQsPage} />
                <Route exact path="/faqs.html" component={FAQsPage} />
                <Route
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicyPage}
                />
                <Route
                  exact
                  path="/privacy_policy.html"
                  component={PrivacyPolicyPage}
                />
                <Route path="/terms*" component={TermsPage} />
                <Route path="/redirect*" component={RedirectPage} />
                <Route exact path="/categories" component={CategoriesPage} />
                <Route path="/search" component={SearchPage} />
                <Route
                  exact
                  path="/sitemap"
                  component={CategoriesSiteMapPage}
                />
                <Route
                  exact
                  path="/sitemap/:category/"
                  component={ProductSiteMapPage}
                />
                <Route exact path="/cart" component={CartPage} />
                <Route
                  exact
                  path="/change-location"
                  component={ChangeLocationPage}
                />
                <Route
                  exact
                  path="/saved-locations"
                  component={SavedLocationsPage}
                />
                <Route exact path="/add-address" component={AddAddressPage} />
                <Route exact path="/edit-address" component={EditAddressPage} />
                <Route exact path="/checkout" component={CheckoutPage} />
                <PrivateRouteService
                  exact
                  path="/myorders"
                  component={MyOrdersPage}
                  isAuthenticated={customerStore.isLoggedIn}
                />
                <PrivateRouteService
                  exact
                  path="/profile"
                  component={ProfilePage}
                  isAuthenticated={customerStore.isLoggedIn}
                />
                <PrivateRouteService
                  exact
                  path="/wallet"
                  component={WalletPage}
                  isAuthenticated={customerStore.isLoggedIn}
                />
                <Route
                  exact
                  path="/products/:prn-:prid/"
                  component={ProductDetailPage}
                />
                <PrivateRouteService
                  exact
                  path="/add-card"
                  component={AddCardPage}
                  isAuthenticated={customerStore.isLoggedIn}
                />
                <Route exact path="/membership" component={MemberShipPage} />
                <Route path="/shop/:slug/:category?" component={BrandPage} />
                <Route path="/shops" component={AllBrandsPage} />
                <Route
                  path="/order/success-payment/:order"
                  component={ThreeDSSuccessPaymentPage}
                />
                <Route path="/unsubscribe" component={UnsubscribePage} />
                <Route
                  path="/:category/:brand?/"
                  component={res => {
                    const { category } = res.match.params;
                    let cid = categoriesStore.getCidBySlug(category);

                    return cid ? <ProductListPage /> : <NotFoundPage />;
                  }}
                />
              </Switch>
              {!isStandalonePage(pathname) && <FooterPart />}
            </Suspense>
          </>
        ) : (
          <FullPageLoaderPart />
        )}
        <ZendeskChat
          defer
          zendeskKey={process.env.REACT_APP_ZENDESK_CHAT_KEY}
          onLoaded={handleChatLoad}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(RouteWrapper);
