import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  root: {
    border: theme.mixins.borderLine2,
    minHeight: "300px"
  },
  cardMedia: {
    width: "100%",
    height: "45vh",
    objectFit: "initial",
    [theme.breakpoints.up("sm")]: {
      height: "55vh"
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      height: "65vh"
    }
  },
  breadCrumbs: {
    borderBottom: theme.mixins.borderLine3,
    padding: theme.spacing(2)
  },
  loadMore: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: "relative",
    width: "100%",
    height: "inherit",
    backgroundColor: "transparent"
  }
}));

export default withCustomStyles;
