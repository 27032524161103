import withSharedStyles from "../theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  dropDownSection: {
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      width: 393
    }
  },
  closeButton: {
    height: 25,
    width: 25,
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    cursor: "pointer"
  },
  enterNewAddress: {
    cursor: "pointer",
    paddingTop: 16,
    paddingBottom: 8,
    paddingLeft: 8
  },
  modalPadding: {
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: 0
  },
  deliveryAddress: {
    color: theme.palette.common.black,
    fontWeight: 600
  },
  labelText: {
    position: "relative",
    top: 10,
    width: 150,
    [theme.breakpoints.up("sm")]: {
      width: 290
    }
  },
  addressList: {
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.05)",
      transition: "all .3s ease-in-out"
    },
    paddingBottom: theme.spacing(2.5),
    paddingLeft: theme.spacing(1)
  },
  hoverUnderlineAnimation: {
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      width: "0",
      height: "2px",
      bottom: "-1px",
      left: "50%",
      transform: "translate(-50%,0%)",
      backgroundColor: theme.palette.primary.main,
      visibility: "hidden",
      transition: "all 0.3s ease-in-out"
    },
    "&:hover:before": {
      visibility: "visible",
      width: "80%"
    }
  }
}));

export default withCustomStyles;
