import withSharedStyles from "./theme/Shared.style";

const withCustomStyles = withSharedStyles(theme => ({
  app: {
    width: "100%",
    maxWidth: "100%"
  },
  upLift: {
    marginBottom: theme.spacing(8)
  },
  mdWidth: {
    width: theme.breakpoints.values.md
  },
  scrollBtn: {
    backgroundColor: "#FFE9D0 !important",
    color: theme.palette.primary.main,
    transition: "all .3s",
    minWidth: 50,
    minHeight: 50,
    padding: 10,
    position: "fixed",
    right: 30,
    zIndex: 9,
    "& svg": {
      fontSize: 28
    },
    [theme.breakpoints.down("sm")]: {
      right: 20
    }
  }
}));

export default withCustomStyles;
