import React from "react";
import { useState, useEffect } from "react";
import withRouter from "react-router/withRouter";

const TimerPart = props => {
  const { initialTime = 0, beforeDeal = "none", isDone } = props;
  const [days] = useState(initialTime.day);
  const [hours, setHours] = useState(initialTime.hour);
  const [minutes, setMinutes] = useState(initialTime.minute);
  const [seconds, setSeconds] = useState(initialTime.second);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (days !== 0) {
        clearInterval(myInterval);
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds <= 0) {
        if (minutes <= 0) {
          if (hours <= 0) {
            clearInterval(myInterval);
            if (isDone) {
              isDone();
            }
          } else {
            setHours(hours - 1);
            setMinutes(59);
            setSeconds(59);
          }
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [seconds]);

  return (
    <>
      {days !== 0 ? (
        <>
          {beforeDeal === "none" ? "" : beforeDeal ? "Starts on " : "Ends on "}
          {days}
        </>
      ) : hours <= 0 && minutes <= 0 && seconds <= 0 ? (
        <>
          {beforeDeal === "none" ? "" : beforeDeal ? "Starts in " : "Ends in "}
          {"0:00:00"}
        </>
      ) : (
        <>
          {beforeDeal === "none" ? "" : beforeDeal ? "Starts in " : "Ends in "}
          {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </>
      )}
    </>
  );
};

export default withRouter(TimerPart);
